// ** React Imports
import { MouseEvent, useState } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import MuiToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton'

// ** Icon Imports
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { Color } from 'src/types/enum-types/Color'

enum State {
  HIGH = 'error',
  MEDIUM = 'warning',
  LOW = 'success'
}

// Styled ToggleButton component
const ToggleButton = styled(MuiToggleButton)<ToggleButtonProps>(({ theme }) => ({
  margin: theme.spacing(1),
  border: 'none !important',
  textTransform: 'capitalize',

  '&:not(:first-of-type)': {
    borderRadius: `${theme.shape.borderRadius}px !important`
  },
  '&:first-of-type': {
    borderRadius: `${theme.shape.borderRadius}px !important`
  },


}))

const FButtonToggleState = () => {
  // ** States
  const [value, setValue] = useState<State | null>(null)
  const [colorValue, setColorValue] = useState<Color>(Color.error)

  const handleAlignment = (event: MouseEvent<HTMLElement>, pValue: State | null) => {
    if(pValue !== null) {
      setColorValue(pValue as unknown as Color)
    }
    setValue(pValue)
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <ToggleButtonGroup color={colorValue} exclusive value={value} onChange={handleAlignment} aria-label='state-button-toggle'>
        <ToggleButton value='error' aria-label='high'>
          <SquareRoundedIcon color='error' fontSize='small' sx={{ mr: 2 }} /> High
        </ToggleButton>
        <ToggleButton value='warning' aria-label='medium'>
          <SquareRoundedIcon color='warning' fontSize='small' sx={{ mr: 2 }} /> Medium
        </ToggleButton>
        <ToggleButton  value='success' aria-label='ship'>
          <SquareRoundedIcon color='success' fontSize='small' sx={{ mr: 2 }} /> Low
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

export default FButtonToggleState
