import moment from 'moment'
import React from 'react'
import tempa from 'src/images/tempa.svg'
import tempa1 from 'src/images/tempa1.svg'

interface SensorInfoProps {
  name: string
  rms: number
  timestamp: number
  temperature: number
}

export default function TestSensor(props: SensorInfoProps) {
  return (
    <div className='flex flex-col w-96 h-60  mt-3 ' style={{ width: '100%' }}>
      <div className=" w-full bg-[#3B456A] flex items-center basis-1/2 pl-6 text-white text-lg font-semibold   ">{props.name}</div>
      <div className="bg-[#1E2745] w-full h-full flex items-center ">
        <div className="flex ml-2">
          <div className='ml-3 '><img className='w-14 ' src={tempa} alt="" /></div>
          <div className='ml-3'>
            <h6 className=' text-[#C9D5FF]'>Bearing Info</h6>
            <h1 className='font-medium text-lg text-[#38E7FF] w-[6.5rem]'>SKF TBU 100</h1>
          </div>
        </div>
        <div className="flex ml-5">  <div className='ml-3 '><img className='w-14 ' src={tempa} alt="" /></div>
          <div className='ml-3'>
            <h6 className='text-[#C9D5FF]'>Vibration (RMS)</h6>
            <h1 className='font-medium text-2xl text-[#38E7FF]'>{props.rms.toFixed(1)} g</h1>
          </div>
        </div>
      </div>
      <div className="bg-[#1E2745] w-full h-full flex items-center">
        <div className="flex ml-2">
          <div className='ml-3 '>
            <img className='w-14 ' src={tempa} alt="" />
          </div>
          <div className='ml-3'>
            <h6 className='text-[#C9D5FF]'>Time</h6>
            <h1 className='font-medium text-lg text-[#38E7FF] w-[6.5rem]'>{moment.unix(props.timestamp).fromNow()}</h1>
            </div>
          </div>
        <div className="flex">
          <div className='ml-5 '>
            <img className='w-14' src={tempa} alt="" />
          </div>
          <div className='ml-3'>
            <h6 className='text-[#C9D5FF]'>Temperature</h6>
            <h1 className='font-medium text-2xl text-[#38E7FF]'>{props.temperature.toFixed(1)}° C</h1>
          </div>
        </div>
      </div>
    </div>
  )
}
