import React, { useContext, useState } from "react"

// ** MUI Imports
import Box, { BoxProps } from '@mui/material/Box'
import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'

// ** Icons Imports
import DotsVertical from 'mdi-material-ui/DotsVertical'

// ** Custom Components Imports
import CustomAvatar from 'src/@core/components/mui/avatar'

// ** Types Imports
import { AssetInfoCardProps } from 'src/@core/components/card-statistics/types'
import { Button, ButtonGroup, CardHeader, Grid, ToggleButton, ToggleButtonGroup, styled } from "@mui/material"

import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import DirectionsTransitRoundedIcon from '@mui/icons-material/DirectionsTransitRounded';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { ModalContext } from "src/context/modalContext"

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: 7,
  px: 12,
  paddingTop: 16,
  paddingBottom: 16
}))

const AssetInfoCard = (props: AssetInfoCardProps) => {
  // ** Props
  const { layoutNumber, title, moduleNumber, sensorNumber, color } = props

  const { setLocationModal, locationModal } = useContext(ModalContext)

  return (
    <Grid item xs={layoutNumber}>
      <Card>
        <CardContent sx={{ py: theme => `${theme.spacing(3)} !important`, px: theme => `${theme.spacing(3)} !important` }}>
          <Grid container item justifyContent='space-between' alignItems='center' spacing={5}>
          <Grid item xs={layoutNumber > 4 ? 3 : 12}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  justifyContent: 'center',
                }}>
                <ButtonGroup variant='text' color={color}>
                  <Button value='train' aria-label='train'>
                    <DirectionsTransitRoundedIcon sx={{ mr: 2 }} /> Train Asset
                  </Button>
                </ButtonGroup>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 3,
                  ml: 3,
                  flexDirection: layoutNumber > 4 ? 'column' : 'row',
                  justifyContent: layoutNumber > 4 ? 'center' : 'space-between',
                }}
              >
                <Typography variant="h6">
                  {title}
                </Typography>
                <Button variant='text' color={color}>Detail</Button>
              </Box>
            </Grid>

            <Grid item xs={layoutNumber > 4 ? 6 : 12}>
              <StyledBox
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: 3
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CustomAvatar
                    skin='light'
                    color={color}
                    sx={{ mr: 3.75, width: '3rem', height: '3rem', borderRadius: '7px !important' }}
                  >
                    <DeviceHubRoundedIcon />
                  </CustomAvatar>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 2 }}>
                    <Typography variant='h4'>{moduleNumber}</Typography>
                    <Typography variant='subtitle2'>Bogies</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <IconButton color={color}>
                      <KeyboardDoubleArrowRightIcon />
                    </IconButton>
                  </Box>
                </Box>
              </StyledBox>
              <StyledBox
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 4,
                  justifyContent: 'center',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CustomAvatar
                    skin='light'
                    color={color}
                    sx={{ mr: 3.75, width: '3rem', height: '3rem', borderRadius: '7px !important' }}
                  >
                    <SensorsRoundedIcon />
                  </CustomAvatar>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 2 }}>
                    <Typography variant='h4'>{sensorNumber}</Typography>
                    <Typography variant='subtitle2'>Sensors</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <IconButton color={color}>
                      <KeyboardDoubleArrowRightIcon />
                    </IconButton>
                  </Box>
                </Box>
              </StyledBox>
            </Grid>
            <Grid item xs={layoutNumber > 4 ? 3 : 12}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button fullWidth variant='outlined' color={color} onClick={() => setLocationModal(true)}><LocationOnIcon sx={{ mr: 1.5 }} /> See The Location</Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>

  )
}

export default AssetInfoCard

AssetInfoCard.defaultProps = {
  color: 'primary',
}
