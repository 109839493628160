
// ** React Imports
import React, { SyntheticEvent, useEffect, useState, useMemo, useCallback } from 'react'

// ** MUI Imports
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Card, Switch, CardContent, Divider, CardActions, InputLabel, TextField, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, InputAdornment, MenuItem, Select, styled, Chip, IconButton } from '@mui/material';
import MuiTab, { TabProps } from '@mui/material/Tab'
import DirectionsTransitRoundedIcon from '@mui/icons-material/DirectionsTransitRounded';
// ** Utils Import
import Image from 'mui-image'

// ** Component Imports
import CustomChip from 'src/@core/components/mui/chip'
import JoinInnerRoundedIcon from '@mui/icons-material/JoinInnerRounded';
// ** Icon Imports
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import MemoryRoundedIcon from '@mui/icons-material/MemoryRounded';
import GpsFixedRoundedIcon from '@mui/icons-material/GpsFixedRounded';
// ** Library Imports
import moment from 'moment'
import { useSensorsLazyQuery } from 'src/generated/graphql';
import { SensorType } from 'src/types/graphql/sensorType';
import SensorInfoCard from 'src/@core/components/card/SensorInfoCard';
import PFigure from 'src/images/figure-1.jpeg'
import PDigitalTwins from 'src/images/digital-twins.jpeg'
import PBogiesView from 'src/images/bogies-view.jpeg'
import MyMap from 'src/@core/components/map/MyMap';
import TrainLocation from 'src/@core/components/map/TrainLocation';

// ** Next Imports

export enum IColorsType {
  active = 'success',
  pending = 'warning',
  inactive = 'secondary'
}

const Tab = styled(MuiTab)<TabProps>(({ theme }) => ({
  minHeight: 48,
  flexDirection: 'row',
  '& svg': {
    marginBottom: '0 !important',
    marginRight: theme.spacing(3)
  }
}));


const ModulesViewPage = () => {



  // ** States
  const [Sensors, { loading }] = useSensorsLazyQuery()

  // ** States
  const [totalRowCount, setTotalRowCount] = useState<number>(0)
  const [sensors, setSensors] = useState<SensorType[]>([])

  const [openEdit, setOpenEdit] = useState<boolean>(false)
  const [value, setValue] = useState<string>('figure')

  // ** Utils Functions

  // ** Table Column Design

  // ** Functions
  const handleEditClickOpen = () => setOpenEdit(true)
  const handleEditClose = () => setOpenEdit(false)

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const fetchTableData = useCallback(
    async () => {

      await Sensors({
        fetchPolicy: "cache-and-network",
      }).then((res) => {
        setTotalRowCount(res.data?.sensors.length || 0);
        setSensors(res.data?.sensors || ([] as SensorType[]));
      });

    },
    []
  );

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6} lg={6}>
          <TabContext value={value}>
            <TabList
              variant='scrollable'
              scrollButtons='auto'
              onChange={handleChange}
              aria-label='forced scroll tabs example'
              sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
            >
              <Tab value='figure' label='Figure' icon={<DirectionsTransitRoundedIcon />} />
              <Tab value='digital-twins' label='Digital Twins' icon={<MemoryRoundedIcon />} />
              <Tab value='bogies-view' label='Bogies View' icon={<JoinInnerRoundedIcon />} />
              <Tab value='location' label='Location' icon={<GpsFixedRoundedIcon />} />
            </TabList>
            <Box sx={{ mt: 6 }}>
              <TabPanel sx={{ p: 0 }} value='figure'>
                <Image src={PFigure} height='calc(100vh - 200px)' />
              </TabPanel>
              <TabPanel sx={{ p: 0 }} value='digital-twins'>
                <Image src={PDigitalTwins} height='calc(100vh - 200px)' />
              </TabPanel>
              <TabPanel sx={{ p: 0 }} value='bogies-view'>
                <Image src={PBogiesView} height='calc(100vh - 200px)' />
              </TabPanel>
              <TabPanel sx={{ p: 0 }} value='location'>
                <Box width={'100%'}>
                  <TrainLocation />
                </Box>
              </TabPanel>
            </Box>
          </TabContext>
        </Grid>
        <Grid item xs={12} md={6} mt={3} lg={6}>
          <Grid container spacing={6}>
            <Grid item xs={12} mb={3}>
                {`Total Sensor Number: ${totalRowCount}`}
            </Grid>
      
            {sensors.map((item, index) => (
                  <SensorInfoCard key={index} title={item.name} sensorNumber={item.sensorNumber} color='primary' />
                ))}
          </Grid>
        </Grid>

      </Grid>
    </>


  )
}

export default ModulesViewPage
