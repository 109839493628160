// ** React Imports
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useReducer,
  useContext,
} from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import {
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  styled,
} from "@mui/material";

// ** Icon Imports
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

// ** Graphql Imports
import { CreateModuleInput, AssetsDocument, ModulesDocument, SensorsDocument, UpdateModuleInput, useCreateModuleMutation, useModulesQuery, useDeleteModuleMutation, useUpdateModuleMutation, useModulesLazyQuery, useModuleLazyQuery } from "src/generated/graphql";

// ** Util Imports
import { hexToRGBA } from "src/@core/utils/hex-to-rgba";

// ** Context Imports
import { GlobalContext } from "src/context/GlobalState";
import { ModalContext } from "src/context/modalContext";

// ** Type Imports
import { Color } from "src/types/enum-types/Color";
import { ModuleType } from "src/types/graphql/moduleType";
import { StateDelete } from "src/types/enum-types/StateDelete";

// Component Imports
import { FTextField } from "src/@core/components/form-field/FTextField";
import PendingToast from "src/@core/components/toast/PendingToast";
import SuccessToast from "src/@core/components/toast/SuccessToast";
import ErrorToast from "src/@core/components/toast/ErrorToast";
import DataGridSpeacial from "src/@core/components/data-grid/DataGridSpeacial";
import DeleteToast from "src/@core/components/toast/DeleteToast";

// ** Library Imports
import moment from "moment";
import * as yup from 'yup'
import { string } from 'yup'
import { Field, Form, Formik, useFormikContext } from "formik";
import { toast } from 'react-toastify';
import AssetAutocompleteField from "src/@core/components/form-field/AutoComplete/AssetAutocompleteField";

const validationSchema = yup.object().shape({
  name: string().required('Bogie name required')
});

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: hexToRGBA(theme.palette.customColors.dialogHeaderText, 0.85)
}))

const AutoChange = () => {
  const { values, setFieldValue } = useFormikContext<CreateModuleInput | UpdateModuleInput>()
  useEffect(() => {
    setFieldValue('name', values.name)
  }, [values.name]);

  return null;
};

const HelpPage = () => {

  // ** Context Datas
  const { setModuleModal, moduleModal } = useContext(ModalContext)
  const { setToastDesignColor } = useContext(GlobalContext);

  // ** States
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [rowCountState, setRowCountState] = useState(
    totalRowCount || 0,
  );
  const [rows, setRows] = useState<ModuleType[]>([]);
  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  const [selectedRowClone, setSelectedRowClone] = useState<string[]>([]);
  const [resetRowSelected, setResetRowSelected] = useState(false);
  const [isNew, setIsNew] = useState<boolean>(true)

  const [initialValues, setInitialValues] = useState<CreateModuleInput | UpdateModuleInput>({
    name: '',
    description: ''
  } as CreateModuleInput)

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  // ** Utils Functions
  const [Modules, {loading}] = useModulesLazyQuery();
  const [Module] = useModuleLazyQuery();
  const [CreateModule] = useCreateModuleMutation();
  const [UpdateModule] = useUpdateModuleMutation();
  const [DeleteModule] = useDeleteModuleMutation();

  // ** DataGrid Column Design
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        flex: 0.11,
        minWidth: 100,
        field: "name",
        headerName: "Bogie Name",
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Typography
              noWrap
              variant="body2"
              sx={{ color: "text.primary", fontWeight: 600 }}
            >
              {row.name}
            </Typography>
          );
        },
      },
      {
        flex: 0.11,
        minWidth: 100,
        field: "asset",
        headerName: "Train",
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Typography
              noWrap
              variant="body2"
              sx={{ color: "text.primary", fontWeight: 600 }}
            >
              {row.asset.name}
            </Typography>
          );
        },
      },
      {
        flex: 0.175,
        minWidth: 110,
        field: "updatedAt",
        headerName: "Last Updated Date",
        align: "right",
        headerAlign: "right",
        renderCell: (params: GridRenderCellParams) => (
          <Typography variant="body2" sx={{ color: "text.primary" }}>
            {moment(params.row.updatedAt).fromNow()}
          </Typography>
        ),
      },
    ],
    []
  );

  const onDelete = (id: string) => {
    DeleteModule({
      variables: {
        id
      },
      refetchQueries: [ModulesDocument, AssetsDocument, ModulesDocument, SensorsDocument],
      awaitRefetchQueries: true
    })
  }


  // ** Functions
  const fillFields = async (id: string) => {
    await Module({
      variables: {
        id: id
      },
      fetchPolicy: "cache-and-network",
    }).then((res) => {
      setInitialValues({
        id: res.data?.module.id,
        assetId: res.data?.module.asset.id,
        name: res.data?.module?.name,
        description: res.data?.module?.description,
      } as UpdateModuleInput)
    });
  }

  const openModuleDialog = async (id = 'new') => {

    if (id === 'new') {
      setInitialValues({
        name: '',
        description: ''
      } as CreateModuleInput)
      setIsNew(true);
    } else {
      await fillFields(id)
      setIsNew(false);
    }

    setModuleModal(true);
  }

  const fetchTableData = useCallback(
    async () => {

      await Modules({
        fetchPolicy: "cache-and-network",
      }).then((res) => {
        setTotalRowCount(res.data?.modules.length || 0);
        setRows(res.data?.modules || ([] as ModuleType[]));
      });

    },
    [paginationModel]
  );

  const onCreateModule = () => {
    openModuleDialog();
  };

  const resetDataGrid = () => {
    setResetRowSelected(true)
    setSelectedRow([])
    setSelectedRowClone([])
    fetchTableData();
  }

  const checkCollection = () => {

    if (selectedRowClone.length > 0) {

      selectedRowClone.forEach(async (id) => {
        await DeleteModule({
          variables: {
            id
          }
        })
      })

      resetDataGrid();
    }
  }

  function reducer(state: any, action: any) {
    switch (action.type) {
      case "QUEUE_FOR_REMOVAL":
        return {
          stateDelete: StateDelete.select
        };
      case "REMOVE":
        return {
          ...state,
          stateDelete: StateDelete.delete
        };
      case "UNDO":
        return {
          undo: true,
          stateDelete: StateDelete.cancel
        };
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    stateDelete: StateDelete.select,
    undo: false
  });

  const notify = () => {
    dispatch({
      type: "QUEUE_FOR_REMOVAL"
    });

    setToastDesignColor(Color.primary)

    toast(<DeleteToast title={`${selectedRow.length} Marka`} onUndo={() => {
      dispatch({
        type: "UNDO"
      });
    }} />, {
      onClose: () => {
        dispatch({ type: "REMOVE" });
      }
    });
  }

  const onDeleteMany = () => {
    setSelectedRowClone(selectedRow);
    setRows(rows.filter(item => !selectedRow.includes(item.id)))
    notify();
  }

  const onUpdate = (id: string) => {
    openModuleDialog(id)
  }

  const onDetail = (id: string) => {
    //router.push(RouterPath.Module_VIEW + id)
  }

  const onSubmit = async (values: CreateModuleInput | UpdateModuleInput) => {
    const promiseToastData = new Promise(async (resolve, reject) => {
      try {
        let returnedName;

        if (isNew) {
          const returnedData = await CreateModule({
            variables: {
              assetId: values.assetId as string,
              name: values.name as string,
              description: values.description as string
            },
            refetchQueries: [ModulesDocument]
          });

          returnedName = returnedData.data?.createModule.name;
        } else {
          const updatedData = values as UpdateModuleInput
          const returnedData = await UpdateModule({
            variables: {
              id: updatedData.id,
              assetId: values.assetId as string,
              name: updatedData.name as string,
              description: updatedData.description
            },
          });

          returnedName = returnedData.data?.updateModule.name;
        }

        resolve(returnedName);
        setModuleModal(false);
        fetchTableData();

      } catch (error) {
        const errorResult = (error as Error).message;
        reject(errorResult)
      }
    });

    toast.promise(
      promiseToastData,
      {
        pending: {
          render() {
            const description = isNew ? 'is creating' : 'is Updating'
            return <PendingToast title="Module" description={description} color="primary" />
          },
          icon: false,
        },
        success: {
          render({ data }) {
            let color = Color.success;
            let description = "Has been successfully created";

            if (!isNew) {
              color = Color.warning;
              description = "Has been successfully updated";
            }
            setToastDesignColor(color)
            return <SuccessToast title={data as string} description={description} color={color} />
          },
        },
        error: {
          render({ data }) {
            setToastDesignColor(Color.error)
            return <ErrorToast title={"Error Occurred"} description={data as string} />
          }
        }
      }
    )
  }

  // ** UseEffects
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalRowCount !== undefined
        ? totalRowCount
        : prevRowCountState,
    );
  }, [totalRowCount, setRowCountState]);

  useEffect(() => {
    if (state.stateDelete == StateDelete.delete && !state.undo) {
      checkCollection();
    } else if (state.undo) {
      resetDataGrid();
    }
  }, [state.stateDelete, state.undo])

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  // ** Components
  const ModuleModal = React.memo(() => {

    return (
      <Dialog
        fullWidth
        open={moduleModal}
        maxWidth='xs'
        scroll="body"
        onClose={() => (setModuleModal(false))}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}>
          <Form>
            <DialogTitle sx={{ p: 0, mb: 5 }}>
              <Title p={5} >
                Create New Bogie
              </Title>
            </DialogTitle>
            <DialogContent
              sx={{ paddingTop: '10px !important' }}
            >
              <AutoChange />
              <Grid container spacing={5}>
                {!isNew && <Field name="id" type="hidden" />}
                <Grid item xs={12}>
                  <AssetAutocompleteField name='assetId' label='Train'/>
                </Grid>
                <Grid item xs={12}>
                  <FTextField
                    name="name"
                    fullWidth
                    label='Bogie Name'
                    placeholder="Maintenance Module"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FTextField
                    name="description"
                    multiline
                    rows={4}
                    fullWidth
                    label='Description'
                    placeholder="Description for Module"
                    type="text"
                  />
                </Grid>
              </Grid>

            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "end",
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setModuleModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                startIcon={<AddRoundedIcon />}
              >
                Create
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    )
  })

  return (
    <Box sx={{
      display: 'flex',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Typography>
      will update soon
      </Typography>
    </Box>
  );
};

export default HelpPage;

