// ** MUI Imports
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import Grid, { GridProps } from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

// ** Icons Imports
import DotsVertical from "mdi-material-ui/DotsVertical";

// ** Custom Components Imports
import CustomAvatar from "src/@core/components/mui/avatar";
import { Divider } from "@mui/material";

// Styled Grid component
const StyledGrid = styled(Grid)<GridProps>(({ theme }) => ({}));

const MostRelevantCard = () => {
  return (
    <Card>
      <Grid container>
        <StyledGrid item xs={12}>
          <CardHeader
            title="Most Revelant Events"
            titleTypographyProps={{
              sx: {
                lineHeight: "2rem !important",
                letterSpacing: "0.15px !important",
              },
            }}
            action={
              <IconButton
                size="small"
                aria-label="settings"
                className="card-more-options"
                sx={{ color: "text.secondary" }}
              >
                <DotsVertical />
              </IconButton>
            }
          />
          <CardContent
            sx={{
              height: "100%",
            }}
          >
            <Grid item container spacing={3}>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle2">Train A1 Gearbox</Typography>
                <CustomAvatar
                  skin="light"
                  color="error"
                  sx={{
                    mr: 1.75,
                    width: "1.8rem",
                    height: "1.8rem",
                    borderRadius: "7px !important",
                  }}
                >
                  H
                </CustomAvatar>
              </Grid>
              <Divider sx={{ width: "100%" }} />
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle2">Train B2 Axle Box</Typography>
                <CustomAvatar
                  skin="light"
                  color="warning"
                  sx={{
                    mr: 1.75,
                    width: "1.8rem",
                    height: "1.8rem",
                    borderRadius: "7px !important",
                  }}
                >
                  M
                </CustomAvatar>
              </Grid>
              <Divider sx={{ width: "100%" }} />
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle2">
                Train C3 Axle Box Roller Bearing 
                </Typography>
                <CustomAvatar
                  skin="light"
                  color="success"
                  sx={{
                    mr: 1.75,
                    width: "1.8rem",
                    height: "1.8rem",
                    borderRadius: "7px !important",
                  }}
                >
                  L
                </CustomAvatar>
              </Grid>
              <Divider sx={{ width: "100%" }} />
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle2">Train D4 Wheel Defects</Typography>
                <CustomAvatar
                  skin="light"
                  color="warning"
                  sx={{
                    mr: 1.75,
                    width: "1.8rem",
                    height: "1.8rem",
                    borderRadius: "7px !important",
                  }}
                >
                  M
                </CustomAvatar>
              </Grid>
            </Grid>
          </CardContent>
        </StyledGrid>
      </Grid>
    </Card>
  );
};

export default MostRelevantCard;
