import { createContext, useState } from "react"

export interface IModal {
    setDepartmentModal: React.Dispatch<React.SetStateAction<boolean>>
    departmentModal: boolean
    setAssetModal: React.Dispatch<React.SetStateAction<boolean>>
    assetModal: boolean
    setModuleModal: React.Dispatch<React.SetStateAction<boolean>>
    moduleModal: boolean
    setSensorModal: React.Dispatch<React.SetStateAction<boolean>>
    sensorModal: boolean
    setLocationModal: React.Dispatch<React.SetStateAction<boolean>>
    locationModal: boolean
}

const defaultState: IModal = {
    departmentModal: false,
    setDepartmentModal:() => false,
    assetModal: false,
    setAssetModal: () => false,
    moduleModal: false,
    setModuleModal: () => false,
    sensorModal: false,
    setSensorModal: () => false,
    locationModal: false,
    setLocationModal: () => false
}

export const ModalContext = createContext<IModal>(defaultState)

type Props = {
    children?: React.ReactNode
}

const ModalProvider = ({ children }: Props) => {
    const [departmentModal, setDepartmentModal] = useState(false)
    const [assetModal, setAssetModal] = useState(false)
    const [moduleModal, setModuleModal] = useState(false)
    const [sensorModal, setSensorModal] = useState(false)
    const [locationModal, setLocationModal] = useState(false)

    const state: IModal = {
        departmentModal,
        setDepartmentModal,
        assetModal,
        setAssetModal,
        moduleModal,
        setModuleModal,
        sensorModal,
        setSensorModal,
        locationModal,
        setLocationModal
      }

    return <ModalContext.Provider value={state} >{ children }</ModalContext.Provider>
}

export default ModalProvider
