import React from 'react'

export const StateBar = (props: { title: string}) => {
    return (
        <div className='w-full bg-[#161A2E] h-10 space-x-4 flex items-center justify-center md:justify-end'>
            <h1 className='flex-grow'>{ props.title }</h1>
            <div className='text-white flex items-center' >
                <span className='bg-red-500 w-2.5 h-2.5 rounded-sm mr-2' />
                High
            </div>
            <div className='text-white flex items-center' >
                <span className='bg-yellow-500 w-2.5 h-2.5 rounded-sm mr-2' />
                Medium
            </div>
            <div className='text-white flex items-center' >
                <span className='bg-green-500 w-2.5 h-2.5 rounded-sm mr-2' />
                Low
            </div>
            <span className='h-7 border-[#353D56] border-r'></span>
            <svg className='w-6 cursor-pointer' viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.391113 0V6.66674H7.14099V0H0.391113Z" fill="#B1B9D8" />
                <path d="M8.64124 0V6.66674H15.3911V0H8.64124Z" fill="#B1B9D8" />
                <path d="M0.391113 15V8.33326H7.14099V15H0.391113Z" fill="#B1B9D8" />
                <path d="M8.64124 8.33326V15H15.3911V8.33326H8.64124Z" fill="#B1B9D8" />
            </svg>
            <svg className='w-6 cursor-pointer' viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.8911 2.83332H5.39111V0.5H14.8911V2.83332ZM5.39111 8.66658V6.33326H14.8911V8.66658H5.39111ZM0.891113 12.0933H2.9564V14.4991H0.891113V12.0933ZM5.39111 12.1667H14.8911V14.5H5.39111V12.1667ZM0.891113 6.43209H2.9564V8.83795H0.891113V6.43209ZM2.96871 2.85426H0.891113V0.5H2.96871V2.85426Z" stroke="#6274B1" />
            </svg>
            <span className='h-7 border-[#353D56] border-r mx-2'></span>
            <svg className='w-8 cursor-pointer' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M11.8911 1.72826C6.21819 1.72826 1.61937 6.32708 1.61937 12C1.61937 17.6729 6.21819 22.2717 11.8911 22.2717C17.564 22.2717 22.1629 17.6729 22.1629 12C22.1629 6.32708 17.564 1.72826 11.8911 1.72826ZM0.641113 12C0.641113 5.7868 5.67791 0.75 11.8911 0.75C18.1043 0.75 23.1411 5.7868 23.1411 12C23.1411 18.2132 18.1043 23.25 11.8911 23.25C5.67791 23.25 0.641113 18.2132 0.641113 12Z" fill="#D8DCEB" />
                <path d="M11.3422 16.515C8.81561 16.1767 7.05227 13.8736 7.39441 11.3753C7.73655 8.87706 10.0789 7.13348 12.5923 7.48479C13.4608 7.6019 14.2372 7.95322 14.8689 8.46068L13.0529 10.0091L18.0402 11.2062L17.8034 6.00145L16.119 7.41973C15.2242 6.62602 14.0925 6.07952 12.816 5.91037C9.42094 5.44194 6.27588 7.79708 5.80215 11.1541C5.32841 14.5112 7.71024 17.634 11.1185 18.0894C13.474 18.4147 15.6979 17.3868 17.0138 15.6042L15.7374 14.6283C14.7768 15.9815 13.1055 16.7622 11.3422 16.515Z" fill="#D8DCEB" />
            </svg>
        </div>
    )
}
