import { Color, ToastDesignColor } from "src/types/enum-types/Color";

export const ToastDesignColors: ToastDesignColor[] = [
    {
        type: Color.primary,
        leftColor: '#007a81',
        rightColor: '#4dacb1',
    },
    {
        type: Color.secondary,
        leftColor: '#9C9FA4',
        rightColor: '#777B82',
    },
    {
        type: Color.success,
        leftColor: '#6AD01F',
        rightColor: '#4CB200',
    },
    {
        type: Color.error,
        leftColor: '#FF6166',
        rightColor: '#E04347',
    },
    {
        type: Color.warning,
        leftColor: '#FFCA64',
        rightColor: '#E09E00',
    },
    {
        type: Color.info,
        leftColor: '#32BAFF',
        rightColor: '#139CE0',
    }
]