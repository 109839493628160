// ** MUI Imports
import { styled, withTheme } from '@mui/material/styles'

// ** Hook Import
import { useSettings } from 'src/@core/hooks/useSettings'
import { ToastContainerProps } from 'react-toastify'
import { BoxProps } from '@mui/material/Box'
import { GlobalContext } from 'src/context/GlobalState'
import { useContext, useEffect } from 'react'
import { spliceColor } from 'src/@core/utils/splice-color'
import { ToastContainer, toast } from 'react-toastify';
import DefaultPalette from 'src/@core/theme/palette'


// ** MUI Imports


const ReactToastify = styled(ToastContainer)<BoxProps>(() => {
  // ** Hook & Var
  const { settings } = useSettings()

  const theme = DefaultPalette(settings.mode);

  const { toastDesignColor } = useContext(GlobalContext);
  
  return {
    '& .Toastify__toast-theme--light': {
      backgroundColor: theme.customColors.bodyBg,
    },
    '& .Toastify__progress-bar': {
      backgroundImage: `linear-gradient(to right, ${spliceColor(toastDesignColor)?.leftColor}, ${spliceColor(toastDesignColor)?.rightColor})`
    },
    '& .Toastify__progress-bar--success': {
      backgroundImage: `linear-gradient(to right, ${spliceColor(toastDesignColor)?.leftColor}, ${spliceColor(toastDesignColor)?.rightColor})`
    },

  }
})

export default ReactToastify
