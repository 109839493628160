import React, { Component, useCallback, useEffect, useState } from 'react'
import { AssetTab } from '../components/AssetTab'
import { useSensorsQuery } from '../generated/graphql'

import Tabbar from '../Tabbar'
import TestSensor from '../TestSensor'

const Sensors = () => {

  //const [ getSensor ] = useGetBySensorNumberMutation();

  const { data } = useSensorsQuery();

  const [sensors, setSensors ] = useState<any[]>([]);

  useEffect(() => {
    const getData = async () => {
      const value = await Promise.all(data?.sensors.map(async (item) => {
        const sensorData = { data: { getBySensorNumber: { rms: 10, temperature: 10, timestamp: 1678802068, id: item.id } } }//await getSensor({ variables: { sensorNumber: item?.sensorNumber || 0} })
        return {
          name: item?.name,
          rms:  sensorData.data?.getBySensorNumber.rms,
          temperature:  sensorData.data?.getBySensorNumber.temperature,
          timestamp:  sensorData.data?.getBySensorNumber.timestamp,
          num:  sensorData.data?.getBySensorNumber.id,
        }
      }) ||[]);

      setSensors(value)
    }
    getData()
  }, [data]);
  

  return (
    <>
      <div className='ml-6 flex'><h3>Modules/</h3><h3>Module-Info</h3></div>

      <div className='flex flex-col sm:flex-col md:flex-col lg:flex-row '>
        <div className=" ml-7 basis-3/5 " style={{ width: '100%' }} >
          <AssetTab />
        </div>
        <div className="flex flex-wrap justify-around mr-12 md:ml-2 basis-2/5 ">
          {
            sensors.map((sensor) => {
              return ( <div className='flex justify-center'>
              <TestSensor name={sensor.name} rms={sensor.rms} temperature={sensor.temperature} timestamp={sensor.timestamp}/>
            </div>)
            })
          }
        </div>
      </div>

    </>


  )
}

export default Sensors
