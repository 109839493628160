

import { Stage } from '@inlet/react-pixi';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, YAxis } from 'recharts';
import Chart from '../components/Chart';
import { StateBar } from '../components/StateBar';
import { socket } from '../socket';

export const Sensor = () => {

    const [data, setData] = useState<any[]>([]);
    const [freq, setFreq] = useState(0.001);
    const [amp, setAmp] = useState(0.5);

    useEffect(() => {
        let timer: NodeJS.Timer;
        function onConnect() {
          console.log('socket connected!');
            let i = 0;
            timer = setInterval(() => {
                const arr = [];

                for (let index = 0; index < 1500; index++) {
                    if (i % 2 === 0)
                    arr.push(Math.random());
                    else
                    arr.push(0);
                }
                i++
                
                socket.emit('data', JSON.stringify(arr));
            }, 1000);
        }
    
        function onDisconnect() {
            console.log('socket disconnected!');
        }
    
        function onSensorDataEvent(value: string) {
          setData((prev) => limitData(prev, JSON.parse(value)))
        }

        function limitData(currentData: any[], data: any[]) {
            let d: any[] = [];
            if (currentData.length > 1500) {
              console.log("Limit reached, dropping first record!");
              d = currentData.slice(1500);
            }

            console.log(d.length)
            return [
              ...(d.length > 0? d: currentData),
              ...data
            ];
          }
    
        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('sensorData', onSensorDataEvent);
    
        return () => {
          socket.off('connect', onConnect);
          socket.off('disconnect', onDisconnect);
          socket.off('sensorData', onSensorDataEvent);
          clearInterval(timer);
        };
      }, []);
    
  return (
    <>
      <StateBar title='REALTIME SENSOR DATA' />
      <Chart data1={data} amp={amp} />
    </>);
}