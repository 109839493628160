import {createContext, Dispatch, SetStateAction, useState} from "react";
import { Color } from "src/types/enum-types/Color";

interface IGlobalContext {
    deger: boolean;
    setDeger: Dispatch<SetStateAction<boolean>>
    dep:boolean;
    setDep:Dispatch<SetStateAction<boolean>>
    asset:boolean;
    setAsset:Dispatch<SetStateAction<boolean>>
    module:boolean;
    setModule:Dispatch<SetStateAction<boolean>>
    sensor:boolean;
    setSensor:Dispatch<SetStateAction<boolean>>
    toastDesignColor: Color;
    setToastDesignColor: Dispatch<SetStateAction<Color>>
    breadcrumbTitle: string;
    setBreadcrumbTitle: Dispatch<SetStateAction<string>>
}

export const GlobalContext = createContext<IGlobalContext>({deger:false, setDeger: () => false,dep:false,setDep: () => false,asset:false,setAsset: () => false,module:false,setModule: () => false,sensor:false,setSensor: () => false, toastDesignColor: Color.primary, setToastDesignColor: () => { }, breadcrumbTitle: "", setBreadcrumbTitle: () => { } });



export const GlobalProvider=(props:any)=>{

    const [deger,setDeger]=useState(false);
    const [dep,setDep]=useState(false);
    const[asset,setAsset]=useState(false);
    const[module,setModule]=useState(false);
    const[sensor,setSensor]=useState(false);
    const [toastDesignColor, setToastDesignColor] = useState(Color.primary);
    const [breadcrumbTitle, setBreadcrumbTitle] = useState("");

    return ( <GlobalContext.Provider value={{deger,setDeger,dep,setDep,asset,setAsset,module,setModule,sensor,setSensor,  toastDesignColor, setToastDesignColor, breadcrumbTitle, setBreadcrumbTitle}}>{props.children}</GlobalContext.Provider>
    );
};