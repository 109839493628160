// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import { useTheme } from '@mui/material/styles'
import CardHeader from '@mui/material/CardHeader'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'

// ** Icons Imports
import Circle from 'mdi-material-ui/Circle'
import DotsVertical from 'mdi-material-ui/DotsVertical'

// ** Third Party Imports
import { ApexOptions } from 'apexcharts'

// ** Custom Components Imports
import ReactApexcharts from 'src/@core/components/react-apexcharts'

const TotalStatisticCard = () => {
  // ** Hook
  const theme = useTheme()

  const options: ApexOptions = {
    chart: {
      sparkline: { enabled: true }
    },
    labels: ['Good', 'Satisfied', 'Unsatisfied', 'Unacceptable'],
    legend: { show: false },
    stroke: { lineCap: 'round' },
    colors: [theme.palette.primary.main, theme.palette.success.main, theme.palette.warning.main, theme.palette.error.main ],
    states: {
      hover: {
        filter: { type: 'none' }
      },
      active: {
        filter: { type: 'none' }
      }
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '40%'
        },
        track: {
          background: 'transparent',
          margin: 10
        },
        dataLabels: {
          name: {
            offsetY: 28
          },
          value: {
            fontSize: '2.125rem',
            offsetY: -12,
            formatter(value: any) {
              return `${value}`
            }
          },
          total: {
            show: true,
            label: 'Total',
            formatter(value: any) {
              return `${value.globals.seriesTotals.reduce((total: number, num: number) => total + num)}`
            }
          }
        }
      }
    }
  }

  return (
    <Card>
      <CardHeader
        title='Bogie Info'
        titleTypographyProps={{
          sx: { lineHeight: '2rem !important', letterSpacing: '0.15px !important' }
        }}
        action={
          <IconButton size='small' aria-label='settings' className='card-more-options' sx={{ color: 'text.secondary' }}>
            <DotsVertical />
          </IconButton>
        }
      />
      <CardContent
        sx={{
          '& .apexcharts-radialbar>g>g:first-of-type .apexcharts-radialbar-area': {
            stroke: 'transparent !important'
          },
          '& .apexcharts-datalabel-label': {
            letterSpacing: '0.4px',
            fontSize: '0.75rem !important',
            fill: `${theme.palette.text.primary} !important`,
            color:`${theme.palette.text.primary} !important`,
          },
          '& .apexcharts-datalabel-value': {
            letterSpacing: '0.25px',
            fill: `${theme.palette.text.primary} !important`,
            fontWeight: '500 !important'
          }
        }}
      >
        <ReactApexcharts type='radialBar' height={243} series={[68, 88, 76, 52]} options={options} />
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-around' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
            <Box sx={{ mb: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Circle sx={{ mr: 1.25, fontSize: '0.75rem', color: 'primary.main' }} />
              <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>68</Typography>
            </Box>
            <Typography variant='caption'>Good</Typography>
          </Box>
          <Divider orientation='vertical' sx={{ m: 0, height: 'auto' }} />
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
            <Box sx={{ mb: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Circle sx={{ mr: 1.25, fontSize: '0.75rem', color: 'success.main' }} />
              <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>88</Typography>
            </Box>
            <Typography variant='caption'>Satisfied</Typography>
          </Box>
          <Divider orientation='vertical' sx={{ m: 0, height: 'auto' }} />
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
            <Box sx={{ mb: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Circle sx={{ mr: 1.25, fontSize: '0.75rem', color: 'warning.main' }} />
              <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>76</Typography>
            </Box>
            <Typography variant='caption'>Unsatisfied</Typography>
          </Box>
          <Divider orientation='vertical' sx={{ m: 0, height: 'auto' }} />
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
            <Box sx={{ mb: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Circle sx={{ mr: 1.25, fontSize: '0.75rem', color: 'error.main' }} />
              <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>52</Typography>
            </Box>
            <Typography variant='caption'>Unacceptable</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default TotalStatisticCard
