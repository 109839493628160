import ReactDOM from 'react-dom/client';
import './index.css';
import moment from 'moment'  // without this line it didn't work
import createCache from '@emotion/cache'

import  App  from './App';
moment.locale('en');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export const myCache = createCache({
  key: 'my-prefix-key',
  stylisPlugins: [
    /* your plugins here */
  ]
})


root.render(
    <App emotionCache={myCache} />
);

