
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";
// ** MUI Imports
import { styled, useTheme } from '@mui/material/styles'

// ** React Imports
import { ReactNode } from 'react'

import "react-toastify/dist/ReactToastify.css";

// ** Loader Import
import NProgress from 'nprogress'

// ** Emotion Imports
import { CacheProvider } from '@emotion/react'
import type { EmotionCache } from '@emotion/cache'

// ** Config Imports
import 'src/configs/i18n'
import { defaultACLObj } from 'src/configs/acl'
import themeConfig from 'src/configs/themeConfig'
import "moment/locale/tr";
import { useContext, useEffect } from 'react'
import { spliceColor } from 'src/@core/utils/splice-color'
// ** Fake-DB Import
//import 'src/@fake-db'

// ** Component Imports
import UserLayout from 'src/layouts/UserLayout'
import AclGuard from 'src/@core/components/auth/AclGuard'
import ThemeComponent from 'src/@core/theme/ThemeComponent'
import GuestGuard from 'src/@core/components/auth/GuestGuard'
import WindowWrapper from 'src/@core/components/window-wrapper'

// ** Spinner Import
import Spinner from 'src/@core/components/spinner'

// ** Contexts
import { SettingsConsumer, SettingsProvider } from 'src/@core/context/settingsContext'

// ** Styled Components

// ** Utils Imports
import { createEmotionCache } from 'src/@core/utils/create-emotion-cache'

// ** scroll
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** Swiper css styles
import 'swiper/css';
import "swiper/css/pagination";

// ** Global css styles
import 'src/styles/globals.css'
import { GlobalContext, GlobalProvider } from 'src/context/GlobalState'
import { ToastContainer } from 'react-toastify'

// ** Third Party Styles Imports
import 'react-datepicker/dist/react-datepicker.css'
import MainLayout from "./layouts/Layout";
import { Color } from "src/types/enum-types/Color"
import ReactToastify from "./@core/styles/libs/react-toastify";

// ** Extend App Props with Emotion
type ExtendedAppProps = {
  emotionCache: EmotionCache
}

type TostifyProps = {
  themeC: any
  toastDesignColor: Color
}

SuperTokens.init({
  appInfo: {
    appName: "delphisonic-panel", // TODO: Your app name
    apiDomain: process.env.REACT_APP_API_DOMAIN || "", // TODO: Change to your app's API domain
    apiBasePath: 'api/auth',
    websiteDomain: process.env.REACT_APP_WEBSITE_DOMAIN || "", // TODO: Change to your app's website domain
  },
  recipeList: [
    EmailPassword.init({
      signInAndUpFeature: {
        disableDefaultUI: true,
        // ...
      },
      // ...
    }),
    Session.init(),
  ],
});

const clientSideEmotionCache = createEmotionCache()

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const { emotionCache = clientSideEmotionCache } = props

  return (
    <GlobalProvider>
      <CacheProvider value={emotionCache}>
        <SettingsProvider>
          <SettingsConsumer>
            {({ settings }) => {
              return (
                <ThemeComponent settings={settings}>
                  <WindowWrapper>
                    <SuperTokensWrapper>
                      <MainLayout />
                    </SuperTokensWrapper>
                  </WindowWrapper>
                  <ReactToastify
                    closeOnClick={false}
                    autoClose={3000}
                    closeButton={false}
                  />
                </ThemeComponent>
              )
            }}
          </SettingsConsumer>
        </SettingsProvider>
      </CacheProvider>
    </GlobalProvider>

  )
}

export default App