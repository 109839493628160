
import { CircularProgress, Typography } from '@mui/material';
import Box from '@mui/material/Box';

interface Props {
  title: string
  color: "primary" | "secondary" | "error" | "warning" | "info" | "success"
  description: string
}

const PendingToast = (props: Props) => {

  const { title, description, color } = props;

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CircularProgress size={25} color={color} />
        <Box ml={5}>
          <Typography>{title}</Typography>
          <Typography variant='caption'>{description}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default PendingToast;
