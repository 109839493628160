import React from 'react'
import { useField } from 'formik'
import TextField from '@mui/material/TextField'
import { InputAdornment } from '@mui/material';


interface Props {
    name: string;
    type: string;
    placeholder?: string;
    alignText?: string;
    adornment?: 'start' | 'end'
    adornmentText?: string
    active?: string
    selectAll?: boolean
    setActive?: (value: React.SetStateAction<string>) => void
    setLastActive?: (value: React.SetStateAction<string>) => void
    [x: string]: unknown
}

export const FTextField = (props: Props) => {
    const {adornmentText, adornment, selectAll} = props
    const [field, meta] = useField(props);

    let startAdornment = null;
    let endAdornment = null;

    if(adornmentText != null) {
        if(adornment === 'start') {
            startAdornment = <InputAdornment position={adornment}>{adornmentText}</InputAdornment>
        } else if(adornment === 'end') {
            endAdornment = <InputAdornment position={adornment}>{adornmentText}</InputAdornment>
        }
    }

    const onFocus = (event: any) => {
        if(selectAll) {
            event.target.select();
        }
        if (props.setLastActive)
            props.setLastActive(props.active || '')

        if (props.setActive)
            props.setActive(props.name)
    }

    return (
        <TextField
            {...field}
            {...props}
            fullWidth
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            onFocus={ onFocus }
            InputProps={{
                startAdornment,
                endAdornment,
                sx: {
                    "& input": {
                        textAlign: props.alignText != null ? props.alignText : 'left',
                    }
                }
            }}
        />
    )
}
