import {MapContainer, TileLayer, Marker, Popup, GeoJSON } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import './MyMap.css';
import sectionsData from './sectionsData.json'
import stationsData from './stationsData.json'
import { Grid, Typography } from '@mui/material';
import PRailCrack from "src/images/rail-crack.jpeg";

const DepartmentBMap = ({
  //mapIsReadyCallback /* To be triggered when a map object is created */,
}) => {
    const position = [40.732239, -73.879500]

    const filteredSectionsData = {
        type: 'FeatureCollection',
        features: sectionsData.features.filter((item => item.properties.lines.find(i => i.line_url_name === '1315-concourse-line')))
    };

    const filteredStationsData = {
        type: 'FeatureCollection',
        features: stationsData.features.filter((item => item.properties.lines.find(i => i.line_url_name === '1315-concourse-line')))
    };

    console.log(filteredStationsData);

    return (<MapContainer style={{ height: '590px' }} center={{ lat: position[0], lng: position[1]}} zoom={11} scrollWheelZoom={false}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <GeoJSON data={filteredSectionsData as GeoJSON.FeatureCollection} />
    {/*<GeoJSON data={filteredStationsData as GeoJSON.FeatureCollection} markersInheritOptions={true} /> */}
    <Marker position={{ lat: 40.854539, lng: -73.9024 }}>
        <Popup>
          <Grid container sx={{
            backgroundColor: '#1a1a1a',
            borderRadius: 1,
            padding: 1,
          }}
          spacing={0}>
            <Grid item>
              <img width={'100%'} src={PRailCrack} alt="crack" />
            </Grid>
            <Grid item>
              <Typography>Rail Crack</Typography>
              <Typography>lat: 40.784539</Typography>
              <Typography>lng: -73.970400</Typography>
            </Grid>
          </Grid>
        </Popup>
      </Marker>
      <Marker position={{ lat: 40.754539, lng: -73.9854 }}>
        <Popup>
        <Grid container sx={{
            backgroundColor: '#1a1a1a',
            borderRadius: 1,
            padding: 1,
          }}
          spacing={0}>
            <Grid item>
              <img width={'100%'} src={PRailCrack} alt="crack" />
            </Grid>
            <Grid item>
              <Typography>Rail Crack</Typography>
              <Typography>lat: 40.754539</Typography>
              <Typography>lng: -73.9854</Typography>
            </Grid>
          </Grid>
        </Popup>
      </Marker>
      <Marker position={{ lat: 40.684539, lng: -73.9784 }}>
        <Popup>
        <Grid container sx={{
            backgroundColor: '#1a1a1a',
            borderRadius: 1,
            padding: 1,
          }}
          spacing={0}>
            <Grid item>
              <img width={'100%'}src={PRailCrack} alt="crack" />
            </Grid>
            <Grid item>
              <Typography>Rail Crack</Typography>
              <Typography>lat: 40.684539</Typography>
              <Typography>lng: -73.9784 </Typography>
            </Grid>
          </Grid>
        </Popup>
      </Marker>
      <Marker position={{ lat: 40.634539, lng: -73.9644 }}>
        <Popup>
        <Grid container sx={{
            backgroundColor: '#1a1a1a',
            borderRadius: 1,
            padding: 1,
          }}
          spacing={0}>
            <Grid item>
              <img width={'100%'} src={PRailCrack} alt="crack" />
            </Grid>
            <Grid item>
              <Typography>Rail Crack</Typography>
              <Typography>lat: 40.634539</Typography>
              <Typography>lng: -73.9644</Typography>
            </Grid>
          </Grid>
        </Popup>
      </Marker>
      <Marker position={{ lat: 40.594539, lng: -73.9554 }}>
        <Popup>
        <Grid container sx={{
            backgroundColor: '#1a1a1a',
            borderRadius: 1,
            padding: 1,
          }}
          spacing={0}>
            <Grid item>
              <img width={'100%'} src={PRailCrack} alt="crack" />
            </Grid>
            <Grid item>
              <Typography>Rail Crack</Typography>
              <Typography>lat: 40.594539</Typography>
              <Typography>lng: -73.9554</Typography>
            </Grid>
          </Grid>
        </Popup>
      </Marker>
  </MapContainer>)
};

export default DepartmentBMap;
