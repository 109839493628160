import { useEffect } from "react";
import { useMeMutation } from "src/generated/graphql"

export const useMe = () => {
    const [meMutation, { data }] = useMeMutation();

    useEffect(() => {
        meMutation()
    }, [meMutation])

    return data?.me
}
