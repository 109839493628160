import React from "react"

// ** MUI Imports
import Box, { BoxProps } from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'

// ** Types Imports
import { Button, Divider, Grid, styled } from "@mui/material"

import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { FTextField } from "../form-field/FTextField"
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

const LastestReportsCard = () => {

  // ** Styled Components
  const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderRadius: 7,
    padding: 10
  }))


  return (
    <Card>
      <CardContent sx={{ pt: theme => `${theme.spacing(0)} !important`, px: theme => `${theme.spacing(0)} !important` }}>
        <Grid container item justifyContent='center' alignItems='center' spacing={5}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Latest Reports
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FTextField name='search' placeholder="Search Device, Date or Route" type='text' />
          </Grid>
          <Grid item xs={12}>
            <StyledBox
              sx={{
                display: 'flex',
                alignItems: 'center',
                direction: 'column',
                justifyContent: 'center',
              }}
            >
              <Grid item container spacing={3}>
                <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                  <Box sx={{ display: 'flex', flexDirection: 'column', m: 1 }}>
                    <Typography variant='subtitle2'>Device</Typography>
                    <Typography color='primary' variant='subtitle1'>E4322</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', }}>
                    <Button variant='text' color='primary'><DownloadRoundedIcon sx={{ mr: 1.5 }} /> Download</Button>
                  </Box>
                </Grid>
                <Divider sx={{ width: '100%' }} />
                <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                  <Box sx={{ display: 'flex', flexDirection: 'column', m: 1 }}>
                    <Typography variant='subtitle2'>Route</Typography>
                    <Typography color='primary' variant='subtitle1'>{'Dyckman St >> 96 St'}</Typography>
                  </Box>
                </Grid>
                <Divider sx={{ width: '100%' }} />
                <Grid item xs={12} display='flex' alignItems='center'>
                  <Box sx={{width:'100%', display: 'flex', flexDirection: 'column', m: 1 }}>
                    <Typography variant='subtitle2'>Date</Typography>
                    <Box sx={{  width:'100%', display: 'flex', justifyContent: 'space-between' }}>
                      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <EventNoteRoundedIcon sx={{m: 1}} />
                      <Typography variant='subtitle1'>{'03.28.2024'}</Typography>
                      </Box>
                      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                         <AccessTimeRoundedIcon sx={{m: 1}}/>
                         <Typography variant='subtitle1'>{'01:13'}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

            </StyledBox>
          </Grid>
          <Grid item xs={12}>
          <StyledBox
              sx={{
                display: 'flex',
                alignItems: 'center',
                direction: 'column',
                justifyContent: 'center',
              }}
            >
              <Grid item container spacing={3}>
                <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                  <Box sx={{ display: 'flex', flexDirection: 'column', m: 1 }}>
                    <Typography variant='subtitle2'>Device</Typography>
                    <Typography color='primary' variant='subtitle1'>E4322</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', }}>
                    <Button variant='text' color='primary'><DownloadRoundedIcon sx={{ mr: 1.5 }} /> Download</Button>
                  </Box>
                </Grid>
                <Divider sx={{ width: '100%' }} />
                <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                  <Box sx={{ display: 'flex', flexDirection: 'column', m: 1 }}>
                    <Typography variant='subtitle2'>Route</Typography>
                    <Typography color='primary' variant='subtitle1'>{'Inwood-207 St >> Rockaway Park-Beach 116 St'}</Typography>
                  </Box>
                </Grid>
                <Divider sx={{ width: '100%' }} />
                <Grid item xs={12} display='flex' alignItems='center'>
                  <Box sx={{width:'100%', display: 'flex', flexDirection: 'column', m: 1 }}>
                    <Typography variant='subtitle2'>Date</Typography>
                    <Box sx={{  width:'100%', display: 'flex', justifyContent: 'space-between' }}>
                      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <EventNoteRoundedIcon sx={{m: 1}} />
                      <Typography variant='subtitle1'>{'03.28.2024'}</Typography>
                      </Box>
                      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                         <AccessTimeRoundedIcon sx={{m: 1}}/>
                         <Typography variant='subtitle1'>{'01:13'}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </StyledBox>
          </Grid>
          <Grid item xs={12}>
          <StyledBox
              sx={{
                display: 'flex',
                alignItems: 'center',
                direction: 'column',
                justifyContent: 'center',
              }}
            >
              <Grid item container spacing={3}>
                <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                  <Box sx={{ display: 'flex', flexDirection: 'column', m: 1 }}>
                    <Typography variant='subtitle2'>Device</Typography>
                    <Typography color='primary' variant='subtitle1'>E4322</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', }}>
                    <Button variant='text' color='primary'><DownloadRoundedIcon sx={{ mr: 1.5 }} /> Download</Button>
                  </Box>
                </Grid>
                <Divider sx={{ width: '100%' }} />
                <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                  <Box sx={{ display: 'flex', flexDirection: 'column', m: 1 }}>
                    <Typography variant='subtitle2'>Route</Typography>
                    <Typography color='primary' variant='subtitle1'>{'Cathedral Pkwy (110 St) >> Beach 105 St'}</Typography>
                  </Box>
                </Grid>
                <Divider sx={{ width: '100%' }} />
                <Grid item xs={12} display='flex' alignItems='center'>
                  <Box sx={{width:'100%', display: 'flex', flexDirection: 'column', m: 1 }}>
                    <Typography variant='subtitle2'>Date</Typography>
                    <Box sx={{  width:'100%', display: 'flex', justifyContent: 'space-between' }}>
                      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <EventNoteRoundedIcon sx={{m: 1}} />
                      <Typography variant='subtitle1'>{'03.28.2024'}</Typography>
                      </Box>
                      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                         <AccessTimeRoundedIcon sx={{m: 1}}/>
                         <Typography variant='subtitle1'>{'01:13'}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </StyledBox>
          </Grid>
        </Grid>
      </CardContent>
    </Card >

  )
}

export default LastestReportsCard

LastestReportsCard.defaultProps = {
  color: 'primary',
}
