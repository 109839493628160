// ** React Imports
import { MouseEvent, useState } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import MuiToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton'

// ** Icon Imports
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { Color } from 'src/types/enum-types/Color'
import TableRowsRoundedIcon from '@mui/icons-material/TableRowsRounded';
import WindowRoundedIcon from '@mui/icons-material/WindowRounded';

enum LayoutType {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

// Styled ToggleButton component
const ToggleButton = styled(MuiToggleButton)<ToggleButtonProps>(({ theme }) => ({
  margin: theme.spacing(1),
  border: 'none !important',
  textTransform: 'capitalize',

  '&:not(:first-of-type)': {
    borderRadius: `${theme.shape.borderRadius}px !important`
  },
  '&:first-of-type': {
    borderRadius: `${theme.shape.borderRadius}px !important`
  },


}))

interface Props {
  setLayoutNumber: any
}

const FButtonToggleLayout = (props: Props) => {

  const {setLayoutNumber} = props

  // ** States
  const [value, setValue] = useState<LayoutType>(LayoutType.HORIZONTAL)

  const handleAlignment = (event: MouseEvent<HTMLElement>, pValue: LayoutType) => {
    if(pValue === null) {
        setValue(LayoutType.HORIZONTAL)
    } else {
        setValue(pValue)
    }

    if(pValue === LayoutType.HORIZONTAL)
    setLayoutNumber(4)
    else
    setLayoutNumber(12)
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <ToggleButtonGroup color='primary' exclusive value={value} onChange={handleAlignment} aria-label='state-button-toggle'>
        <ToggleButton value={LayoutType.HORIZONTAL} aria-label='horizontal'>
          <WindowRoundedIcon />
        </ToggleButton>
        <ToggleButton value={LayoutType.VERTICAL} aria-label='vertical'>
          <TableRowsRoundedIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

export default FButtonToggleLayout
