export enum Color {
  primary = "primary",
  secondary = 'secondary',
  success = "success",
  error = 'error',
  warning = "warning",
  info = "info",
}

export type ToastDesignColor = {
  type: Color,
  leftColor: string,
  rightColor: string,
}
