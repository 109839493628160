import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type Asset = {
  __typename?: 'Asset';
  /** Asset Create DateTime */
  createdAt: Scalars['DateTime'];
  /** Department */
  department: Department;
  /** Asset Description */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Asset Name */
  name: Scalars['String'];
  /** Asset Update DateTime */
  updatedAt: Scalars['DateTime'];
};

export type CreateAssetInput = {
  /** Department ID */
  departmentId: Scalars['ID'];
  /** Asset Description */
  description?: InputMaybe<Scalars['String']>;
  /** Asset Name */
  name: Scalars['String'];
};

export type CreateDepartmentInput = {
  /** Department Description */
  description?: InputMaybe<Scalars['String']>;
  /** Department Name */
  name: Scalars['String'];
};

export type CreateModuleInput = {
  /** Asset ID */
  assetId: Scalars['ID'];
  /** Module Description */
  description?: InputMaybe<Scalars['String']>;
  /** Module Name */
  name: Scalars['String'];
};

export type CreateSensorInput = {
  /** Sensor Description */
  description?: InputMaybe<Scalars['String']>;
  /** Module ID */
  moduleId: Scalars['ID'];
  /** Sensor Name */
  name: Scalars['String'];
  /** Sensor Number */
  sensorNumber: Scalars['Int'];
};

export type Department = {
  __typename?: 'Department';
  /** Department Create DateTime */
  createdAt: Scalars['DateTime'];
  /** Department Description */
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Department Name */
  name: Scalars['String'];
  /** Department Update DateTime */
  updatedAt: Scalars['DateTime'];
};

export type Module = {
  __typename?: 'Module';
  /** Asset */
  asset: Asset;
  /** Module Create DateTime */
  createdAt: Scalars['DateTime'];
  /** Module Description */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Module Name */
  name: Scalars['String'];
  /** Module Update DateTime */
  updatedAt: Scalars['DateTime'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createAsset: Asset;
  createDepartment: Department;
  createModule: Module;
  createSensor: Sensor;
  deleteAsset: Scalars['Boolean'];
  deleteDepartment: Scalars['Boolean'];
  deleteModule: Scalars['Boolean'];
  deleteSensor: Scalars['Boolean'];
  getBySensorNumber: Sensor;
  me: User;
  totalSensorsNumber: Scalars['Int'];
  updateAsset: Asset;
  updateDepartment: Department;
  updateModule: Module;
  updateSensor: Sensor;
};


export type MutationCreateAssetArgs = {
  createAssetInput: CreateAssetInput;
};


export type MutationCreateDepartmentArgs = {
  createDepartmentInput: CreateDepartmentInput;
};


export type MutationCreateModuleArgs = {
  createModuleInput: CreateModuleInput;
};


export type MutationCreateSensorArgs = {
  createSensorInput: CreateSensorInput;
};


export type MutationDeleteAssetArgs = {
  id: Scalars['String'];
};


export type MutationDeleteDepartmentArgs = {
  id: Scalars['String'];
};


export type MutationDeleteModuleArgs = {
  id: Scalars['String'];
};


export type MutationDeleteSensorArgs = {
  id: Scalars['String'];
};


export type MutationGetBySensorNumberArgs = {
  sensorNumber: Scalars['Int'];
};


export type MutationTotalSensorsNumberArgs = {
  moduleId: Scalars['String'];
};


export type MutationUpdateAssetArgs = {
  updateAssetInput: UpdateAssetInput;
};


export type MutationUpdateDepartmentArgs = {
  updateDepartmentInput: UpdateDepartmentInput;
};


export type MutationUpdateModuleArgs = {
  updateModuleInput: UpdateModuleInput;
};


export type MutationUpdateSensorArgs = {
  updateSensorInput: UpdateSensorInput;
};

export type Query = {
  __typename?: 'Query';
  asset: Asset;
  assets: Array<Asset>;
  department: Department;
  departments: Array<Department>;
  module: Module;
  modules: Array<Module>;
  sensor: Sensor;
  sensors: Array<Sensor>;
};


export type QueryAssetArgs = {
  id: Scalars['String'];
};


export type QueryAssetsArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type QueryDepartmentArgs = {
  id: Scalars['String'];
};


export type QueryDepartmentsArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type QueryModuleArgs = {
  id: Scalars['String'];
};


export type QueryModulesArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type QuerySensorArgs = {
  id: Scalars['String'];
};


export type QuerySensorsArgs = {
  name?: InputMaybe<Scalars['String']>;
};

export type Sensor = {
  __typename?: 'Sensor';
  /** Sensor Create DateTime */
  createdAt: Scalars['DateTime'];
  /** Sensor Description */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Module */
  module: Module;
  /** Sensor Name */
  name: Scalars['String'];
  /** Sensor Number */
  sensorNumber: Scalars['Int'];
  /** Sensor Update DateTime */
  updatedAt: Scalars['DateTime'];
};

export type UpdateAssetInput = {
  /** Department ID */
  departmentId?: InputMaybe<Scalars['ID']>;
  /** Asset Description */
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** Asset Name */
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateDepartmentInput = {
  /** Department Description */
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** Department Name */
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateModuleInput = {
  /** Asset ID */
  assetId?: InputMaybe<Scalars['ID']>;
  /** Module Description */
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Module Name */
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateSensorInput = {
  /** Sensor Description */
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Module ID */
  moduleId?: InputMaybe<Scalars['ID']>;
  /** Sensor Name */
  name?: InputMaybe<Scalars['String']>;
  /** Sensor Number */
  sensorNumber?: InputMaybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  /** Email */
  email: Scalars['String'];
  /** Full Name */
  fullName: Scalars['String'];
  id: Scalars['ID'];
};

export type AssetQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AssetQuery = { __typename?: 'Query', asset: { __typename?: 'Asset', id: string, name: string, description?: string | null, createdAt: any, updatedAt: any, department: { __typename?: 'Department', id: string, name: string } } };

export type AssetsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
}>;


export type AssetsQuery = { __typename?: 'Query', assets: Array<{ __typename?: 'Asset', id: string, name: string, description?: string | null, createdAt: any, updatedAt: any, department: { __typename?: 'Department', id: string, name: string } }> };

export type CreateAssetMutationVariables = Exact<{
  departmentId: Scalars['ID'];
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
}>;


export type CreateAssetMutation = { __typename?: 'Mutation', createAsset: { __typename?: 'Asset', id: string, name: string, description?: string | null, createdAt: any, updatedAt: any, department: { __typename?: 'Department', id: string, name: string } } };

export type UpdateAssetMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  departmentId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
}>;


export type UpdateAssetMutation = { __typename?: 'Mutation', updateAsset: { __typename?: 'Asset', id: string, name: string, description?: string | null, createdAt: any, updatedAt: any, department: { __typename?: 'Department', id: string, name: string } } };

export type DeleteAssetMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteAssetMutation = { __typename?: 'Mutation', deleteAsset: boolean };

export type DepartmentQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type DepartmentQuery = { __typename?: 'Query', department: { __typename?: 'Department', id: string, name: string, description?: string | null, createdAt: any, updatedAt: any } };

export type DepartmentsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
}>;


export type DepartmentsQuery = { __typename?: 'Query', departments: Array<{ __typename?: 'Department', id: string, name: string, description?: string | null, createdAt: any, updatedAt: any }> };

export type CreateDepartmentMutationVariables = Exact<{
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
}>;


export type CreateDepartmentMutation = { __typename?: 'Mutation', createDepartment: { __typename?: 'Department', id: string, name: string, description?: string | null, createdAt: any, updatedAt: any } };

export type UpdateDepartmentMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
}>;


export type UpdateDepartmentMutation = { __typename?: 'Mutation', updateDepartment: { __typename?: 'Department', id: string, name: string, description?: string | null, createdAt: any, updatedAt: any } };

export type DeleteDepartmentMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteDepartmentMutation = { __typename?: 'Mutation', deleteDepartment: boolean };

export type ModuleQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ModuleQuery = { __typename?: 'Query', module: { __typename?: 'Module', id: string, name: string, description?: string | null, createdAt: any, updatedAt: any, asset: { __typename?: 'Asset', id: string, name: string } } };

export type ModulesQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
}>;


export type ModulesQuery = { __typename?: 'Query', modules: Array<{ __typename?: 'Module', id: string, name: string, description?: string | null, createdAt: any, updatedAt: any, asset: { __typename?: 'Asset', id: string, name: string } }> };

export type CreateModuleMutationVariables = Exact<{
  description: Scalars['String'];
  assetId: Scalars['ID'];
  name: Scalars['String'];
}>;


export type CreateModuleMutation = { __typename?: 'Mutation', createModule: { __typename?: 'Module', id: string, name: string, description?: string | null, createdAt: any, updatedAt: any, asset: { __typename?: 'Asset', id: string, name: string } } };

export type UpdateModuleMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  assetId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
}>;


export type UpdateModuleMutation = { __typename?: 'Mutation', updateModule: { __typename?: 'Module', id: string, name: string, description?: string | null, createdAt: any, updatedAt: any, asset: { __typename?: 'Asset', id: string, name: string } } };

export type DeleteModuleMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteModuleMutation = { __typename?: 'Mutation', deleteModule: boolean };

export type SensorQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type SensorQuery = { __typename?: 'Query', sensor: { __typename?: 'Sensor', id: string, name: string, sensorNumber: number, description?: string | null, createdAt: any, updatedAt: any, module: { __typename?: 'Module', id: string, name: string } } };

export type SensorsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
}>;


export type SensorsQuery = { __typename?: 'Query', sensors: Array<{ __typename?: 'Sensor', id: string, name: string, sensorNumber: number, description?: string | null, createdAt: any, updatedAt: any, module: { __typename?: 'Module', id: string, name: string } }> };

export type CreateSensorMutationVariables = Exact<{
  moduleId: Scalars['ID'];
  sensorNumber: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
}>;


export type CreateSensorMutation = { __typename?: 'Mutation', createSensor: { __typename?: 'Sensor', id: string, name: string, sensorNumber: number, description?: string | null, createdAt: any, updatedAt: any, module: { __typename?: 'Module', id: string, name: string } } };

export type UpdateSensorMutationVariables = Exact<{
  id: Scalars['ID'];
  moduleId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  sensorNumber?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
}>;


export type UpdateSensorMutation = { __typename?: 'Mutation', updateSensor: { __typename?: 'Sensor', id: string, name: string, sensorNumber: number, description?: string | null, createdAt: any, updatedAt: any, module: { __typename?: 'Module', id: string, name: string } } };

export type DeleteSensorMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteSensorMutation = { __typename?: 'Mutation', deleteSensor: boolean };

export type MeMutationVariables = Exact<{ [key: string]: never; }>;


export type MeMutation = { __typename?: 'Mutation', me: { __typename?: 'User', id: string, email: string, fullName: string } };


export const AssetDocument = gql`
    query Asset($id: String!) {
  asset(id: $id) {
    id
    name
    department {
      id
      name
    }
    description
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useAssetQuery__
 *
 * To run a query within a React component, call `useAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetQuery(baseOptions: Apollo.QueryHookOptions<AssetQuery, AssetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetQuery, AssetQueryVariables>(AssetDocument, options);
      }
export function useAssetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetQuery, AssetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetQuery, AssetQueryVariables>(AssetDocument, options);
        }
export type AssetQueryHookResult = ReturnType<typeof useAssetQuery>;
export type AssetLazyQueryHookResult = ReturnType<typeof useAssetLazyQuery>;
export type AssetQueryResult = Apollo.QueryResult<AssetQuery, AssetQueryVariables>;
export const AssetsDocument = gql`
    query Assets($name: String) {
  assets(name: $name) {
    id
    name
    department {
      id
      name
    }
    description
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useAssetsQuery__
 *
 * To run a query within a React component, call `useAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAssetsQuery(baseOptions?: Apollo.QueryHookOptions<AssetsQuery, AssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetsQuery, AssetsQueryVariables>(AssetsDocument, options);
      }
export function useAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetsQuery, AssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetsQuery, AssetsQueryVariables>(AssetsDocument, options);
        }
export type AssetsQueryHookResult = ReturnType<typeof useAssetsQuery>;
export type AssetsLazyQueryHookResult = ReturnType<typeof useAssetsLazyQuery>;
export type AssetsQueryResult = Apollo.QueryResult<AssetsQuery, AssetsQueryVariables>;
export const CreateAssetDocument = gql`
    mutation CreateAsset($departmentId: ID!, $name: String!, $description: String) {
  createAsset(
    createAssetInput: {departmentId: $departmentId, name: $name, description: $description}
  ) {
    id
    name
    department {
      id
      name
    }
    description
    createdAt
    updatedAt
  }
}
    `;
export type CreateAssetMutationFn = Apollo.MutationFunction<CreateAssetMutation, CreateAssetMutationVariables>;

/**
 * __useCreateAssetMutation__
 *
 * To run a mutation, you first call `useCreateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetMutation, { data, loading, error }] = useCreateAssetMutation({
 *   variables: {
 *      departmentId: // value for 'departmentId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateAssetMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssetMutation, CreateAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssetMutation, CreateAssetMutationVariables>(CreateAssetDocument, options);
      }
export type CreateAssetMutationHookResult = ReturnType<typeof useCreateAssetMutation>;
export type CreateAssetMutationResult = Apollo.MutationResult<CreateAssetMutation>;
export type CreateAssetMutationOptions = Apollo.BaseMutationOptions<CreateAssetMutation, CreateAssetMutationVariables>;
export const UpdateAssetDocument = gql`
    mutation UpdateAsset($id: String!, $name: String!, $departmentId: ID, $description: String) {
  updateAsset(
    updateAssetInput: {id: $id, name: $name, departmentId: $departmentId, description: $description}
  ) {
    id
    name
    department {
      id
      name
    }
    description
    createdAt
    updatedAt
  }
}
    `;
export type UpdateAssetMutationFn = Apollo.MutationFunction<UpdateAssetMutation, UpdateAssetMutationVariables>;

/**
 * __useUpdateAssetMutation__
 *
 * To run a mutation, you first call `useUpdateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetMutation, { data, loading, error }] = useUpdateAssetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      departmentId: // value for 'departmentId'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateAssetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssetMutation, UpdateAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssetMutation, UpdateAssetMutationVariables>(UpdateAssetDocument, options);
      }
export type UpdateAssetMutationHookResult = ReturnType<typeof useUpdateAssetMutation>;
export type UpdateAssetMutationResult = Apollo.MutationResult<UpdateAssetMutation>;
export type UpdateAssetMutationOptions = Apollo.BaseMutationOptions<UpdateAssetMutation, UpdateAssetMutationVariables>;
export const DeleteAssetDocument = gql`
    mutation DeleteAsset($id: String!) {
  deleteAsset(id: $id)
}
    `;
export type DeleteAssetMutationFn = Apollo.MutationFunction<DeleteAssetMutation, DeleteAssetMutationVariables>;

/**
 * __useDeleteAssetMutation__
 *
 * To run a mutation, you first call `useDeleteAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetMutation, { data, loading, error }] = useDeleteAssetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAssetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAssetMutation, DeleteAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAssetMutation, DeleteAssetMutationVariables>(DeleteAssetDocument, options);
      }
export type DeleteAssetMutationHookResult = ReturnType<typeof useDeleteAssetMutation>;
export type DeleteAssetMutationResult = Apollo.MutationResult<DeleteAssetMutation>;
export type DeleteAssetMutationOptions = Apollo.BaseMutationOptions<DeleteAssetMutation, DeleteAssetMutationVariables>;
export const DepartmentDocument = gql`
    query Department($id: String!) {
  department(id: $id) {
    id
    name
    description
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useDepartmentQuery__
 *
 * To run a query within a React component, call `useDepartmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDepartmentQuery(baseOptions: Apollo.QueryHookOptions<DepartmentQuery, DepartmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepartmentQuery, DepartmentQueryVariables>(DepartmentDocument, options);
      }
export function useDepartmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepartmentQuery, DepartmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepartmentQuery, DepartmentQueryVariables>(DepartmentDocument, options);
        }
export type DepartmentQueryHookResult = ReturnType<typeof useDepartmentQuery>;
export type DepartmentLazyQueryHookResult = ReturnType<typeof useDepartmentLazyQuery>;
export type DepartmentQueryResult = Apollo.QueryResult<DepartmentQuery, DepartmentQueryVariables>;
export const DepartmentsDocument = gql`
    query Departments($name: String) {
  departments(name: $name) {
    id
    name
    description
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useDepartmentsQuery__
 *
 * To run a query within a React component, call `useDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useDepartmentsQuery(baseOptions?: Apollo.QueryHookOptions<DepartmentsQuery, DepartmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepartmentsQuery, DepartmentsQueryVariables>(DepartmentsDocument, options);
      }
export function useDepartmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepartmentsQuery, DepartmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepartmentsQuery, DepartmentsQueryVariables>(DepartmentsDocument, options);
        }
export type DepartmentsQueryHookResult = ReturnType<typeof useDepartmentsQuery>;
export type DepartmentsLazyQueryHookResult = ReturnType<typeof useDepartmentsLazyQuery>;
export type DepartmentsQueryResult = Apollo.QueryResult<DepartmentsQuery, DepartmentsQueryVariables>;
export const CreateDepartmentDocument = gql`
    mutation CreateDepartment($name: String!, $description: String) {
  createDepartment(
    createDepartmentInput: {name: $name, description: $description}
  ) {
    id
    name
    description
    createdAt
    updatedAt
  }
}
    `;
export type CreateDepartmentMutationFn = Apollo.MutationFunction<CreateDepartmentMutation, CreateDepartmentMutationVariables>;

/**
 * __useCreateDepartmentMutation__
 *
 * To run a mutation, you first call `useCreateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDepartmentMutation, { data, loading, error }] = useCreateDepartmentMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDepartmentMutation, CreateDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDepartmentMutation, CreateDepartmentMutationVariables>(CreateDepartmentDocument, options);
      }
export type CreateDepartmentMutationHookResult = ReturnType<typeof useCreateDepartmentMutation>;
export type CreateDepartmentMutationResult = Apollo.MutationResult<CreateDepartmentMutation>;
export type CreateDepartmentMutationOptions = Apollo.BaseMutationOptions<CreateDepartmentMutation, CreateDepartmentMutationVariables>;
export const UpdateDepartmentDocument = gql`
    mutation UpdateDepartment($id: String!, $name: String!, $description: String) {
  updateDepartment(
    updateDepartmentInput: {id: $id, name: $name, description: $description}
  ) {
    id
    name
    description
    createdAt
    updatedAt
  }
}
    `;
export type UpdateDepartmentMutationFn = Apollo.MutationFunction<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>;

/**
 * __useUpdateDepartmentMutation__
 *
 * To run a mutation, you first call `useUpdateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDepartmentMutation, { data, loading, error }] = useUpdateDepartmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>(UpdateDepartmentDocument, options);
      }
export type UpdateDepartmentMutationHookResult = ReturnType<typeof useUpdateDepartmentMutation>;
export type UpdateDepartmentMutationResult = Apollo.MutationResult<UpdateDepartmentMutation>;
export type UpdateDepartmentMutationOptions = Apollo.BaseMutationOptions<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>;
export const DeleteDepartmentDocument = gql`
    mutation DeleteDepartment($id: String!) {
  deleteDepartment(id: $id)
}
    `;
export type DeleteDepartmentMutationFn = Apollo.MutationFunction<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>;

/**
 * __useDeleteDepartmentMutation__
 *
 * To run a mutation, you first call `useDeleteDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDepartmentMutation, { data, loading, error }] = useDeleteDepartmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>(DeleteDepartmentDocument, options);
      }
export type DeleteDepartmentMutationHookResult = ReturnType<typeof useDeleteDepartmentMutation>;
export type DeleteDepartmentMutationResult = Apollo.MutationResult<DeleteDepartmentMutation>;
export type DeleteDepartmentMutationOptions = Apollo.BaseMutationOptions<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>;
export const ModuleDocument = gql`
    query Module($id: String!) {
  module(id: $id) {
    id
    name
    asset {
      id
      name
    }
    description
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useModuleQuery__
 *
 * To run a query within a React component, call `useModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useModuleQuery(baseOptions: Apollo.QueryHookOptions<ModuleQuery, ModuleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModuleQuery, ModuleQueryVariables>(ModuleDocument, options);
      }
export function useModuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModuleQuery, ModuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModuleQuery, ModuleQueryVariables>(ModuleDocument, options);
        }
export type ModuleQueryHookResult = ReturnType<typeof useModuleQuery>;
export type ModuleLazyQueryHookResult = ReturnType<typeof useModuleLazyQuery>;
export type ModuleQueryResult = Apollo.QueryResult<ModuleQuery, ModuleQueryVariables>;
export const ModulesDocument = gql`
    query Modules($name: String) {
  modules(name: $name) {
    id
    name
    asset {
      id
      name
    }
    description
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useModulesQuery__
 *
 * To run a query within a React component, call `useModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModulesQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useModulesQuery(baseOptions?: Apollo.QueryHookOptions<ModulesQuery, ModulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModulesQuery, ModulesQueryVariables>(ModulesDocument, options);
      }
export function useModulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModulesQuery, ModulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModulesQuery, ModulesQueryVariables>(ModulesDocument, options);
        }
export type ModulesQueryHookResult = ReturnType<typeof useModulesQuery>;
export type ModulesLazyQueryHookResult = ReturnType<typeof useModulesLazyQuery>;
export type ModulesQueryResult = Apollo.QueryResult<ModulesQuery, ModulesQueryVariables>;
export const CreateModuleDocument = gql`
    mutation CreateModule($description: String!, $assetId: ID!, $name: String!) {
  createModule(
    createModuleInput: {name: $name, assetId: $assetId, description: $description}
  ) {
    id
    name
    asset {
      id
      name
    }
    description
    createdAt
    updatedAt
  }
}
    `;
export type CreateModuleMutationFn = Apollo.MutationFunction<CreateModuleMutation, CreateModuleMutationVariables>;

/**
 * __useCreateModuleMutation__
 *
 * To run a mutation, you first call `useCreateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModuleMutation, { data, loading, error }] = useCreateModuleMutation({
 *   variables: {
 *      description: // value for 'description'
 *      assetId: // value for 'assetId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateModuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateModuleMutation, CreateModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateModuleMutation, CreateModuleMutationVariables>(CreateModuleDocument, options);
      }
export type CreateModuleMutationHookResult = ReturnType<typeof useCreateModuleMutation>;
export type CreateModuleMutationResult = Apollo.MutationResult<CreateModuleMutation>;
export type CreateModuleMutationOptions = Apollo.BaseMutationOptions<CreateModuleMutation, CreateModuleMutationVariables>;
export const UpdateModuleDocument = gql`
    mutation UpdateModule($id: ID!, $name: String!, $assetId: ID, $description: String) {
  updateModule(
    updateModuleInput: {id: $id, name: $name, assetId: $assetId, description: $description}
  ) {
    id
    name
    asset {
      id
      name
    }
    description
    createdAt
    updatedAt
  }
}
    `;
export type UpdateModuleMutationFn = Apollo.MutationFunction<UpdateModuleMutation, UpdateModuleMutationVariables>;

/**
 * __useUpdateModuleMutation__
 *
 * To run a mutation, you first call `useUpdateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleMutation, { data, loading, error }] = useUpdateModuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      assetId: // value for 'assetId'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateModuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModuleMutation, UpdateModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModuleMutation, UpdateModuleMutationVariables>(UpdateModuleDocument, options);
      }
export type UpdateModuleMutationHookResult = ReturnType<typeof useUpdateModuleMutation>;
export type UpdateModuleMutationResult = Apollo.MutationResult<UpdateModuleMutation>;
export type UpdateModuleMutationOptions = Apollo.BaseMutationOptions<UpdateModuleMutation, UpdateModuleMutationVariables>;
export const DeleteModuleDocument = gql`
    mutation DeleteModule($id: String!) {
  deleteModule(id: $id)
}
    `;
export type DeleteModuleMutationFn = Apollo.MutationFunction<DeleteModuleMutation, DeleteModuleMutationVariables>;

/**
 * __useDeleteModuleMutation__
 *
 * To run a mutation, you first call `useDeleteModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModuleMutation, { data, loading, error }] = useDeleteModuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteModuleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteModuleMutation, DeleteModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteModuleMutation, DeleteModuleMutationVariables>(DeleteModuleDocument, options);
      }
export type DeleteModuleMutationHookResult = ReturnType<typeof useDeleteModuleMutation>;
export type DeleteModuleMutationResult = Apollo.MutationResult<DeleteModuleMutation>;
export type DeleteModuleMutationOptions = Apollo.BaseMutationOptions<DeleteModuleMutation, DeleteModuleMutationVariables>;
export const SensorDocument = gql`
    query Sensor($id: String!) {
  sensor(id: $id) {
    id
    name
    sensorNumber
    module {
      id
      name
    }
    description
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useSensorQuery__
 *
 * To run a query within a React component, call `useSensorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSensorQuery(baseOptions: Apollo.QueryHookOptions<SensorQuery, SensorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SensorQuery, SensorQueryVariables>(SensorDocument, options);
      }
export function useSensorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorQuery, SensorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SensorQuery, SensorQueryVariables>(SensorDocument, options);
        }
export type SensorQueryHookResult = ReturnType<typeof useSensorQuery>;
export type SensorLazyQueryHookResult = ReturnType<typeof useSensorLazyQuery>;
export type SensorQueryResult = Apollo.QueryResult<SensorQuery, SensorQueryVariables>;
export const SensorsDocument = gql`
    query Sensors($name: String) {
  sensors(name: $name) {
    id
    name
    sensorNumber
    module {
      id
      name
    }
    description
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useSensorsQuery__
 *
 * To run a query within a React component, call `useSensorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSensorsQuery(baseOptions?: Apollo.QueryHookOptions<SensorsQuery, SensorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SensorsQuery, SensorsQueryVariables>(SensorsDocument, options);
      }
export function useSensorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorsQuery, SensorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SensorsQuery, SensorsQueryVariables>(SensorsDocument, options);
        }
export type SensorsQueryHookResult = ReturnType<typeof useSensorsQuery>;
export type SensorsLazyQueryHookResult = ReturnType<typeof useSensorsLazyQuery>;
export type SensorsQueryResult = Apollo.QueryResult<SensorsQuery, SensorsQueryVariables>;
export const CreateSensorDocument = gql`
    mutation CreateSensor($moduleId: ID!, $sensorNumber: Int!, $name: String!, $description: String!) {
  createSensor(
    createSensorInput: {moduleId: $moduleId, name: $name, sensorNumber: $sensorNumber, description: $description}
  ) {
    id
    name
    sensorNumber
    module {
      id
      name
    }
    description
    createdAt
    updatedAt
  }
}
    `;
export type CreateSensorMutationFn = Apollo.MutationFunction<CreateSensorMutation, CreateSensorMutationVariables>;

/**
 * __useCreateSensorMutation__
 *
 * To run a mutation, you first call `useCreateSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSensorMutation, { data, loading, error }] = useCreateSensorMutation({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *      sensorNumber: // value for 'sensorNumber'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateSensorMutation(baseOptions?: Apollo.MutationHookOptions<CreateSensorMutation, CreateSensorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSensorMutation, CreateSensorMutationVariables>(CreateSensorDocument, options);
      }
export type CreateSensorMutationHookResult = ReturnType<typeof useCreateSensorMutation>;
export type CreateSensorMutationResult = Apollo.MutationResult<CreateSensorMutation>;
export type CreateSensorMutationOptions = Apollo.BaseMutationOptions<CreateSensorMutation, CreateSensorMutationVariables>;
export const UpdateSensorDocument = gql`
    mutation UpdateSensor($id: ID!, $moduleId: ID, $name: String!, $sensorNumber: Int, $description: String) {
  updateSensor(
    updateSensorInput: {id: $id, moduleId: $moduleId, name: $name, sensorNumber: $sensorNumber, description: $description}
  ) {
    id
    name
    sensorNumber
    module {
      id
      name
    }
    description
    createdAt
    updatedAt
  }
}
    `;
export type UpdateSensorMutationFn = Apollo.MutationFunction<UpdateSensorMutation, UpdateSensorMutationVariables>;

/**
 * __useUpdateSensorMutation__
 *
 * To run a mutation, you first call `useUpdateSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSensorMutation, { data, loading, error }] = useUpdateSensorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      moduleId: // value for 'moduleId'
 *      name: // value for 'name'
 *      sensorNumber: // value for 'sensorNumber'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateSensorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSensorMutation, UpdateSensorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSensorMutation, UpdateSensorMutationVariables>(UpdateSensorDocument, options);
      }
export type UpdateSensorMutationHookResult = ReturnType<typeof useUpdateSensorMutation>;
export type UpdateSensorMutationResult = Apollo.MutationResult<UpdateSensorMutation>;
export type UpdateSensorMutationOptions = Apollo.BaseMutationOptions<UpdateSensorMutation, UpdateSensorMutationVariables>;
export const DeleteSensorDocument = gql`
    mutation DeleteSensor($id: String!) {
  deleteSensor(id: $id)
}
    `;
export type DeleteSensorMutationFn = Apollo.MutationFunction<DeleteSensorMutation, DeleteSensorMutationVariables>;

/**
 * __useDeleteSensorMutation__
 *
 * To run a mutation, you first call `useDeleteSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSensorMutation, { data, loading, error }] = useDeleteSensorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSensorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSensorMutation, DeleteSensorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSensorMutation, DeleteSensorMutationVariables>(DeleteSensorDocument, options);
      }
export type DeleteSensorMutationHookResult = ReturnType<typeof useDeleteSensorMutation>;
export type DeleteSensorMutationResult = Apollo.MutationResult<DeleteSensorMutation>;
export type DeleteSensorMutationOptions = Apollo.BaseMutationOptions<DeleteSensorMutation, DeleteSensorMutationVariables>;
export const MeDocument = gql`
    mutation Me {
  me {
    id
    email
    fullName
  }
}
    `;
export type MeMutationFn = Apollo.MutationFunction<MeMutation, MeMutationVariables>;

/**
 * __useMeMutation__
 *
 * To run a mutation, you first call `useMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [meMutation, { data, loading, error }] = useMeMutation({
 *   variables: {
 *   },
 * });
 */
export function useMeMutation(baseOptions?: Apollo.MutationHookOptions<MeMutation, MeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MeMutation, MeMutationVariables>(MeDocument, options);
      }
export type MeMutationHookResult = ReturnType<typeof useMeMutation>;
export type MeMutationResult = Apollo.MutationResult<MeMutation>;
export type MeMutationOptions = Apollo.BaseMutationOptions<MeMutation, MeMutationVariables>;