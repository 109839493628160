import React, { useEffect, useRef, useState } from "react";

import { WebglPlot, WebglLine, ColorRGBA } from "webgl-plot";

let webglp: WebglPlot;
let line: WebglLine;

type prop = {
  data1: any[];
  amp: number;
  noise?: number;
};

export default function Chart({ data1, amp, noise }: prop) {
  const canvasMain = useRef<HTMLCanvasElement>(null);
  const [data, setData] = useState<Float32Array>(new Float32Array(1500));
  let i = 1;

  useEffect(() => {


    if (canvasMain.current) {
      const devicePixelRatio = window.devicePixelRatio || 1;
      canvasMain.current.width =
        canvasMain.current.clientWidth * devicePixelRatio;
      canvasMain.current.height =
        canvasMain.current.clientHeight * devicePixelRatio;

      webglp = new WebglPlot(canvasMain.current);
      const numX = 3000;


      line = new WebglLine(new ColorRGBA(1, 0, 0, 1), numX);
      webglp.addLine(line);

      line.arrangeX();
    }

  }, []);

  useEffect(() => {
    let id = 0;
    let renderPlot = () => {
      //const freq = 0.001;
      //const noise = 0.1;
      //const amp = 0.5;
      webglp.linesData.forEach((line) => {
        console.log(data1);
        (line as WebglLine).replaceArrayY(data1.slice());
      });

      id = requestAnimationFrame(renderPlot);
      webglp.update();
    };
    id = requestAnimationFrame(renderPlot);

    return () => {
      renderPlot = () => {};
      cancelAnimationFrame(id);
    };
  }, [data1]);

  const canvasStyle = {
    width: "100%",
    height: "70vh"
  };

  return (
    <div>
      <canvas style={canvasStyle} ref={canvasMain} />
    </div>
  );
}
