
import { Typography, IconButton } from '@mui/material';
import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import CustomAvatar from 'src/@core/components/mui/avatar'
import Box from '@mui/material/Box';

interface Props {
  title: string
  onUndo: any
  closeToast?: any
}

const DeleteToast = (props: Props) => {

  const { title, onUndo, closeToast } = props;
  
  const handleClick = () => {
    onUndo();
    closeToast(false);
  };

  return (
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CustomAvatar
            color='primary'
            skin='light'
            variant="rounded"
            sx={{ mr: 3, width: '2.3rem', height: '2.3rem' }}
          >
            <DeleteRoundedIcon fontSize="medium" />
          </CustomAvatar>
          <Box>
            <Typography variant='subtitle1'>{title}</Typography>
            <Typography variant='caption'>Queued for deletion</Typography>
          </Box>
        </Box>
        <IconButton color="primary" onClick={handleClick}>
          <SettingsBackupRestoreRoundedIcon />
        </IconButton>
      </Box>
  )
}

export default DeleteToast;
