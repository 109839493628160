// ** React Import
import { ReactNode } from 'react'

// ** Next Import
//import Link from 'next/link'

// ** MUI Imports
import IconButton from '@mui/material/IconButton'
import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'

// ** Icons
import Close from 'mdi-material-ui/Close'
import CircleOutline from 'mdi-material-ui/CircleOutline'
import RecordCircleOutline from 'mdi-material-ui/RecordCircleOutline'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'
import { Link } from '@mui/material'
import Image from 'mui-image'
import Logo from 'src/images/logo.png'
import OnlyLogo from 'src/images/only-logo.png'

interface Props {
  hidden: boolean
  navHover: boolean
  settings: Settings
  collapsedNavWidth: number
  menuLockedIcon?: ReactNode
  menuUnlockedIcon?: ReactNode
  navigationBorderWidth: number
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
  verticalNavMenuBranding?: (props?: any) => ReactNode
}

type NavFooterProps = {
  navCollapsed: boolean;
};
// ** Styled Components
const MenuFooterWrapper = styled(Box)<NavFooterProps>(({ theme, navCollapsed }) => ({
  position: 'fixed',
  bottom: 0,
  width: navCollapsed ? '64px' : '260px',
  display: 'flex',
  alignItems: 'center',
  marginBottom: 0,
  backgroundColor: theme.palette.customColors.sidebar,
  paddingRight: theme.spacing(4.5),
  transition: 'padding .25s ease-in-out',
  minHeight: theme.mixins.toolbar.minHeight
}))

const StyledLink = styled('a')({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none'
})

const VerticalNavFooter = (props: Props) => {
  // ** Props
  const {
    navHover,
    settings,
    collapsedNavWidth,
    navigationBorderWidth,
    verticalNavMenuBranding: userVerticalNavMenuBranding
  } = props

  // ** Hooks & Vars
  const { navCollapsed } = settings

  const menuHeaderPaddingLeft = () => {
    if (navCollapsed && !navHover) {
      if (userVerticalNavMenuBranding) {
        return 0
      } else {
        return (collapsedNavWidth - navigationBorderWidth - 32) / 8
      }
    } else {
      return 6
    }
  }

  return (
    <MenuFooterWrapper className='nav-footer' sx={{ pl: menuHeaderPaddingLeft() }} navCollapsed={navCollapsed}>
      {userVerticalNavMenuBranding ? (
        userVerticalNavMenuBranding(props)
      ) : (
        <Link href='https:/delphisonic.com'>
          <StyledLink>
            <Box sx={{
                width: '100%',
                display: navCollapsed && !navHover ? 'none' : 'flex', transition: 'display .25s ease-in-out',
                marginLeft: navCollapsed ? 0 : 9,
                marginBottom: 3
                
              }} alignItems='center'>
              <Box>
                <Image duration={1000} width={128} src={Logo} />
              </Box>
            </Box>
            <Box sx={{
                display: navCollapsed && !navHover ? 'flex' : 'none', transition: 'display .25s ease-in-out'
              }}>
                <Image width={32} duration={500} src={OnlyLogo} />
            </Box>
          </StyledLink>
        </Link>
      )}
    </MenuFooterWrapper>
  )
}

export default VerticalNavFooter
