// ** Component Imports

// ** Graphql Imports

// ** Library Imports
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import moment from "moment";

// ** MUI Imports
import {
  Box,
  Grid,
  Typography,
  useTheme,
  Card,
  CardContent,
  Theme,
} from "@mui/material";

// ** React Imports
import { useEffect, useState } from "react";
import Chip from "../mui/chip";
import DatasetIcon from "@mui/icons-material/Dataset";
// ** Types
type TemperatureAndHumidityLiveChartProps = {
  label: string;
  temperature: number;
  time: Date;
};

type SerieDataType = {
  x: Date;
  y: number;
};

const TemperatureAndHumidityLiveChart = (
  props: TemperatureAndHumidityLiveChartProps
) => {
  // ** Props
  const { label, temperature, time } = props;

  // ** States
  const [serieDatasForTemperature, setSerieDatasForTemperature] = useState<
    SerieDataType[]
  >([]);

  const [dataSetQuantity, setDataSetQuantity] = useState<number>(0);

  // ** Context Datas
  const theme = useTheme();

  // ** Variables
  const series = [
    {
      name: "Temperature (°C)",
      data: serieDatasForTemperature,
    }
  ];

  const options: ApexOptions = {
    chart: {
      id: " temperatureAndHumidity",
      type: "area",
      height: "100%",
    },
    xaxis: {
      labels: {
        formatter(value: string) {
          return moment(value).format("H:mm:ss");
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: theme.palette.text.primary,
        fontSize: "18px",
      },
    },
    colors: [theme.palette.warning.main, theme.palette.success.main],
  };

  useEffect(() => {}, []);

  // ** UseEffects
  useEffect(() => {
    if (serieDatasForTemperature.length > 19) {
      setSerieDatasForTemperature([
        ...serieDatasForTemperature.slice(1),
        { x: time, y: Number(temperature.toFixed(2)) },
      ]);

    } else {
      setSerieDatasForTemperature([
        ...serieDatasForTemperature,
        { x: time, y: Number(temperature.toFixed(2)) },
      ]);

    }

    setDataSetQuantity(dataSetQuantity + 1);
  }, [temperature, time]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: theme.palette.background.paper,
            px: 3,
            py: 2,
            borderRadius: 1,
          }}
        >
          <Typography variant="subtitle1">{label}</Typography>
          <Chip
            skin="light"
            label={`Dataset Quantity: ${dataSetQuantity}`}
            color={"primary"}
            icon={<DatasetIcon fontSize="small" />}
            sx={{
              py: 4,
              px: 1,
              height: 32,
              "&.MuiChip-light": {
                "&:hover": {
                  color: (theme: Theme) =>
                    theme.palette.customColors.customizedChipText,
                },
              },
              fontSize: "0.8rem",
              "& .MuiChip-label": {
                fontWeight: 600,
                lineHeight: 1.4,
              },
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Card
          sx={{
            height: 300,
            maxHeight: 400,
          }}
        >
          <CardContent
            sx={{
              p: 0,
              width: "100%",
              height: "100%",
              "& .apexcharts-legend-text": {
                color: (theme: Theme) => `${theme.palette.text.primary} !important`,
              },
              "& .apexcharts-xcrosshairs.apexcharts-active": {
                opacity: 0,
              },
              "& .apexcharts-yaxis-label": {
                fill: (theme: Theme) => `${theme.palette.text.primary} !important`,
              },
              "& .apexcharts-xaxis-label": {
                fill: (theme: Theme) => `${theme.palette.text.primary} !important`,
              },
              "& .apexcharts-tooltip": {
                color: (theme: Theme) => `${theme.palette.text.primary} !important`,
              },
              "& .apexcharts-tooltip-title": {
                color: (theme: Theme) => `${theme.palette.text.primary} !important`,
                backgroundColor: (theme: Theme) =>
                  `${theme.palette.background.paper} !important`,
                border: 0,
              },
              "& .apexcharts-tooltip-box": {
                color: (theme: Theme) => `${theme.palette.text.primary} !important`,
                backgroundColor: (theme: Theme) =>
                  `${theme.palette.background.paper} !important`,
              },
              "& .apexcharts-tooltip .apexcharts-tooltip-series-group": {
                color: (theme: Theme) => `${theme.palette.text.primary} !important`,
                backgroundColor: (theme: Theme) =>
                  `${theme.palette.background.paper} !important`,
              },
            }}
          >
            <Chart
              options={options}
              series={series}
              type="area"
              height={"100%"}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TemperatureAndHumidityLiveChart;
