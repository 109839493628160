
import { Typography } from '@mui/material';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import CustomAvatar from 'src/@core/components/mui/avatar'
import Box from '@mui/material/Box';

interface Props {
  title: string
  description: string
}

const ErrorToast = (props: Props) => {

  const { title, description } = props;

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CustomAvatar
          color='error'
          skin='light'
          variant="rounded"
          sx={{ mr: 3, width: '2.3rem', height: '2.3rem' }}
          >
          <PriorityHighRoundedIcon fontSize="medium" />
        </CustomAvatar>
        <Box>
          <Typography>{title}</Typography>
          <Typography variant='caption'>{description}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ErrorToast;
