// ** React Imports
import { MouseEvent, useState } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import MuiToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton'

// ** Icon Imports
import DirectionsTransitRoundedIcon from '@mui/icons-material/DirectionsTransitRounded';

// Styled ToggleButton component
const ToggleButton = styled(MuiToggleButton)<ToggleButtonProps>(({ theme }) => ({
  margin: theme.spacing(1),
  border: 'none !important',
  paddingTop: theme.spacing(3),
  paddingRight: theme.spacing(4),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(4),

  '&:not(:first-of-type)': {
    borderRadius: `${theme.shape.borderRadius}px !important`
  },
  '&:first-of-type': {
    borderRadius: `${theme.shape.borderRadius}px !important`
  },

}))

const FButtonToggleTrain = () => {
  // ** States
  const [value, setValue] = useState<string | null>('train')

  const handleAlignment = (event: MouseEvent<HTMLElement>, pValue: string | null) => {
    if(pValue === null) {
      setValue('train')
    } else {
      setValue(pValue)
    }
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <ToggleButtonGroup color='primary' exclusive value={value} onChange={handleAlignment} aria-label='asset-button-toggle'>
        <ToggleButton value='train' aria-label='train'>
          <DirectionsTransitRoundedIcon sx={{mr: 2}}/> Train
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

export default FButtonToggleTrain
