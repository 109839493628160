
import { ApolloProvider } from '@apollo/client';
import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Session, { SessionAuth } from "supertokens-auth-react/recipe/session";

import ModalProvider from '../context/modalContext';
import AssetsPage from '../pages/Assets';
import Sensors from '../pages/departments22';
import ModulesPage from '../pages/Modules';
import { Sensor } from '../views/Sensor';
import BlankLayout from '../@core/layouts/BlankLayout';
import LoginPage from 'src/pages/login';
import UserLayout from './UserLayout';
import Error404 from '../pages/404';
import DashboardPage from '../pages/Dashboard';
import DepartmentsPage from 'src/pages/Departments';
import SensorsPage from 'src/pages/Sensors';
import AssetsViewPage from 'src/pages/AssetsView';
import ModulesViewPage from 'src/pages/ModulesView';
import client from 'src/@core/apollo';
import ReportViewPage from 'src/pages/ReportView';
import DepartmentsViewPage from 'src/pages/DepartmentsView';
import HelpPage from 'src/pages/Help';

const MainLayout = () => {
  const sessionContext = Session.useSessionContext();

  if (sessionContext.loading) {
    return null;
  }

  const SignIn: React.FC = () => (
    <BlankLayout>
      <LoginPage />
    </BlankLayout>
  );

  const MainRoute: React.FC = () => (
    <ModalProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SessionAuth><UserLayout /></SessionAuth>}>
            <Route index element={<DashboardPage />} />
            <Route path="departments" element={<DepartmentsPage />} />
            <Route path="departmens/view" element={<DepartmentsViewPage />} />
            <Route path="assets" element={<AssetsPage />} />
            <Route path="assets/view" element={<AssetsViewPage />} />
            <Route path="modules" element={<ModulesPage />} />
            <Route path="modules/view" element={<ModulesViewPage />} />
            <Route path="sensors" element={<SensorsPage />} />
            <Route path="sensors/sensor-info" element={<Sensor />} />
            <Route path="departments/dep-modules" element={<ModulesPage />} />
            <Route path="modules/modules-info" element={<Sensors />} />
            <Route path="reports" element={<ReportViewPage />} />
            <Route path="assets/assets-info" element={<AssetsPage />} />
            <Route path="help" element={<HelpPage />} />
            <Route path="maintenance-history" element={<HelpPage />} />
            <Route path="alarms" element={<HelpPage />} />
            <Route
              path="*"
              element={<Navigate to="404" replace />}
            />
          </Route>
          <Route path='404' element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </ModalProvider>
  );

  if (sessionContext.doesSessionExist) {
    // We wrap this with <SessionAuth /> so that
    // all claims are validated before showing the logged in UI.
    // For example, if email verification is switched on, and
    // the user's email is not verified, then <SessionAuth />
    // will redirect to the email verification page.
    return (
      <ApolloProvider client={client}>
        <MainRoute />
      </ApolloProvider >
    )
  } else {
    return (
      <SignIn />
    )
  }

}

export default MainLayout
