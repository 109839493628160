// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

// ** Icons Imports

import DotsVertical from "mdi-material-ui/DotsVertical";

// ** Custom Components Imports
import CustomAvatar from "src/@core/components/mui/avatar";
import { Divider } from "@mui/material";
import BuildRoundedIcon from "@mui/icons-material/BuildRounded";

const MostRecentCard = () => {
  return (
    <Card sx={{ marginTop: 5 }}>
      <Grid container>
        <Grid item xs={12}>
          <CardHeader
            title="Action Required"
            titleTypographyProps={{
              sx: {
                lineHeight: "2rem !important",
                letterSpacing: "0.15px !important",
              },
            }}
            action={
              <IconButton
                size="small"
                aria-label="settings"
                className="card-more-options"
                sx={{ color: "text.secondary" }}
              >
                <DotsVertical />
              </IconButton>
            }
          />
          <CardContent
            sx={{
              height: "100%",
            }}
          >
            <Grid item container spacing={3}>
              <Grid item xs={12} display="flex" alignItems="center">
                <CustomAvatar
                  skin="light"
                  color="primary"
                  sx={{
                    mr: 1.75,
                    width: "1.8rem",
                    height: "1.8rem",
                    borderRadius: "7px !important",
                  }}
                >
                  <BuildRoundedIcon fontSize="small" />
                </CustomAvatar>
                <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                  <Typography variant="subtitle1" sx={{ lineHeight: 1.2 }}>
                    Train A1
                  </Typography>
                  <Typography variant="subtitle2" sx={{ lineHeight: 1.2 }}>
                    Check Gearbox oil level
                  </Typography>
                </Box>
              </Grid>
              <Divider sx={{ width: "100%" }} />
              <Grid item xs={12} display="flex" alignItems="center">
                <CustomAvatar
                  skin="light"
                  color="primary"
                  sx={{
                    mr: 1.75,
                    width: "1.8rem",
                    height: "1.8rem",
                    borderRadius: "7px !important",
                  }}
                >
                  <BuildRoundedIcon fontSize="small" />
                </CustomAvatar>
                <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                  <Typography variant="subtitle1" sx={{ lineHeight: 1.2 }}>
                    Train B2
                  </Typography>
                  <Typography variant="subtitle2" sx={{ lineHeight: 1.2 }}>
                    Axle Box control
                  </Typography>
                </Box>
              </Grid>
              <Divider sx={{ width: "100%" }} />
              <Grid item xs={12} display="flex" alignItems="center">
                <CustomAvatar
                  skin="light"
                  color="primary"
                  sx={{
                    mr: 1.75,
                    width: "1.8rem",
                    height: "1.8rem",
                    borderRadius: "7px !important",
                  }}
                >
                  <BuildRoundedIcon fontSize="small" />
                </CustomAvatar>
                <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                  <Typography variant="subtitle1" sx={{ lineHeight: 1.2 }}>
                    Train C3
                  </Typography>
                  <Typography variant="subtitle2" sx={{ lineHeight: 1.2 }}>
                    Change the Axle Box Roller Bearing
                  </Typography>
                </Box>
              </Grid>
              <Divider sx={{ width: "100%" }} />
              <Grid item xs={12} display="flex" alignItems="center">
                <CustomAvatar
                  skin="light"
                  color="primary"
                  sx={{
                    mr: 1.75,
                    width: "1.8rem",
                    height: "1.8rem",
                    borderRadius: "7px !important",
                  }}
                >
                  <BuildRoundedIcon fontSize="small" />
                </CustomAvatar>
                <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                  <Typography variant="subtitle1" sx={{ lineHeight: 1.2 }}>
                    Train D4
                  </Typography>
                  <Typography variant="subtitle2" sx={{ lineHeight: 1.2 }}>
                    Wheel Visual check
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

export default MostRecentCard;
