// ** Icon Imports
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import WorkspacesRoundedIcon from '@mui/icons-material/WorkspacesRounded';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import AirplayRoundedIcon from '@mui/icons-material/AirplayRounded';
import BuildRoundedIcon from '@mui/icons-material/BuildRounded';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded';
import AlarmRoundedIcon from '@mui/icons-material/AlarmRounded';
import DirectionsSubwayRoundedIcon from '@mui/icons-material/DirectionsSubwayRounded';
import GroupWorkRoundedIcon from '@mui/icons-material/GroupWorkRounded';

// ** Types Imports
import { VerticalNavItemsType } from 'src/@core/layouts/types'

const navigation = (): VerticalNavItemsType => {

  const overviewPage = {
    title: 'Dashboard',
    icon: AirplayRoundedIcon,
    isVisible: true,
    path: '/',
  }

  const sectionTitleForDetail =
  {
    sectionTitle: 'Detail'
  }

  const departments = {
    title: 'Subway Lines',
    icon: DnsRoundedIcon,
    isVisible: true,
    children: [
      {
        title: 'Manage',
        path: '/departments'
      },
      {
        title: 'View',
        path: '/departmens/view'
      }
    ]
  }

  const assets = {
    title: 'Trains',
    icon: DirectionsSubwayRoundedIcon,
    isVisible: true,
    children: [
      {
        title: 'Manage',
        path: '/assets'
      },
      {
        title: 'View',
        path: '/assets/view'
      }
    ]
  }

  const modules = {
    title: 'Bogies',
    
    icon: GroupWorkRoundedIcon,
    isVisible: true,
    children: [
      {
        title: 'Manage',
        path: '/modules'
      },
      {
        title: 'View',
        path: '/modules/view'
      }
    ]
  }

  const sensors = {
    title: 'Sensors',
    icon: SensorsRoundedIcon,
    isVisible: true,
    children: [
      {
        title: 'Manage',
        path: '/sensors'
      }
    ]
  }

  const sectionTitleForMaintenance =
  {
    sectionTitle: 'Maintenance'
  }

  const maintenanceDepartment = {
    title: 'Maintenance',
    icon: BuildRoundedIcon,
    isVisible: false,
    children: [
      {
        title: 'Maintenance History',
        path: '/maintenance-history'
      },
    ]
  }

  const sectionTitleForReport =
  {
    sectionTitle: 'Report'
  }

  const reports = {
    title: 'Reports',
    icon: EqualizerRoundedIcon,
    isVisible: false,
    path: '/reports'
  }

  const alarms = {
    title: 'Alarms',
    icon: AlarmRoundedIcon,
    isVisible: false,
    path: '/alarms'
  }

  const sectionTitleForHelp =
  {
    sectionTitle: 'Help'
  }

  const help = {
    title: 'Help',
    icon: QuestionMarkRoundedIcon,
    path: '/help'
  }

  return [
    overviewPage, sectionTitleForDetail, departments, assets, modules, sensors, sectionTitleForMaintenance, maintenanceDepartment, sectionTitleForReport, reports,alarms, sectionTitleForHelp, help
  ]
}

export default navigation
