// ** React Imports
import React, { SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react'

// ** Next Imports

// ** MUI Imports
import Grid, { GridProps } from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from "@mui/material/Card";
import { CardContent, InputLabel, Switch, CardActions, Chip, Typography, CardHeader, IconButton, Collapse, Box, Stack, styled, TypographyProps, Dialog, DialogActions, DialogContent, DialogTitle, BoxProps } from '@mui/material';
import Divider from '@mui/material/Divider';
import { ChevronDown, ChevronUp } from 'mdi-material-ui';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
// ** Library Imports
import { Field, Form, Formik, useFormikContext } from 'formik';
import { toast } from "react-toastify";
import _ from 'lodash';
import axios from 'axios';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MuiTab, { TabProps } from '@mui/material/Tab'

// ** Icon Imports
import FDefaultCurrencyTextField from 'src/@core/components/form-field/DefaultCurrencyTextField';
import FButtonToggle from 'src/@core/components/form-field/FButtonToggleTrain';
import FButtonToggleState from 'src/@core/components/form-field/FButtonToggleState';
import FButtonToggleLayout from 'src/@core/components/form-field/FButtonToggleLayout';
import { hexToRGBA } from 'src/@core/utils/hex-to-rgba';
import AssetInfoCard from 'src/@core/components/card/AssetInfoCard';
import { AssetType } from 'src/types/graphql/assetType';
import { useAssetsLazyQuery } from 'src/generated/graphql';
import LastestReportsCard from 'src/@core/components/card/LastestReportsCard';
import { ModalContext } from 'src/context/modalContext';
import FButtonToggleTrain from 'src/@core/components/form-field/FButtonToggleTrain';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

import CustomAvatar from 'src/@core/components/mui/avatar'

import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import CardWidgetsTotalRevenue from 'src/@core/components/card/TotalStatisticCard';
import CardTotalEarnings from 'src/@core/components/card/CardTotalEarings';
import EvulationCard from 'src/@core/components/card/CardTotalEarings';
import MostRelevantCard from 'src/@core/components/card/MostRelevantCard';
import MostRecentCard from 'src/@core/components/card/MostRecentCard';

// ** Onchange Component
const AutoChange = (props: any) => {
  const { values, setFieldValue } = useFormikContext<any>()

  return null;
};

// ** Styled Components
const TotalText = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  color: hexToRGBA(theme.palette.customColors.CurrentText, 0.6)
}))

const Tab = styled(MuiTab)<TabProps>(({ theme }) => ({
  minHeight: 48,
  flexDirection: 'row',
  '& svg': {
    marginBottom: '0 !important',
    marginRight: theme.spacing(3)
  }
}));

const StyledGrid = styled(Grid)<GridProps>(({ theme }) => ({
  position: 'sticky',
  top: 56,
  zIndex: 5,
  backgroundColor: theme.palette.background.default
}))

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: hexToRGBA(theme.palette.customColors.dialogHeaderText, 0.85)
}))

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: 7,
  padding: 10
}))


const DashboardPage = () => {

  const [Assets, { loading }] = useAssetsLazyQuery()

  // ** States
  const [active, setActive] = useState('')
  const [totalRowCount, setTotalRowCount] = useState<number>(0)
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [getEnableForDiscount, setGetEnableForDiscount] = useState<boolean>(false);
  const [getEnableForProfitRate, setGetEnableForProfitRate] = useState<boolean>(false);
  const [visibleOfFavorites, setVisibleOfFavorites] = useState<boolean>(false)
  const [visibleOfMinQuantity, setVisibleOfMinQuantity] = useState<boolean>(false)
  const [layoutNumber, setLayoutNumber] = useState<number>(4)
  const [assets, setAssets] = useState<AssetType[]>([])
  const [defaultCurrency, setDefaultCurrency] = useState<boolean>(true)
  const [value, setValue] = useState<string>('detail')

  let initialValues: any;

  // by passing empty array at the end, this will always return the same function, compatible with removeEventListener
  const keyDownHandler = useCallback((event: any) => {
    if (event.keyCode === 13 && event.target.nodeName !== "BUTTON") {
      event.preventDefault();
    }
  }, []);


  const fetchTableData = useCallback(
    async () => {

      await Assets({
        fetchPolicy: "cache-and-network",
      }).then((res) => {
        setTotalRowCount(res.data?.assets.length || 0);
        setAssets(res.data?.assets || ([] as AssetType[]));
      });

    },
    []
  );

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);


  const onBack = () => {
    //router.back();
  }

  const submitForm = async (values: any) => {

  }

  const { setLocationModal, locationModal } = useContext(ModalContext)

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }


  return (
    <>
      <Grid container spacing={7}>
        <Grid item container spacing={5} alignItems='center' p={2} xs={12}>
          <Grid item xs={4}>
            <CardWidgetsTotalRevenue />
          </Grid>
          <Grid item xs={4}>
            <MostRelevantCard />
            <MostRecentCard />
          </Grid>
          <Grid item xs={4}>
            <EvulationCard />
          </Grid>
        </Grid>
      </Grid>
    </>

  )
}

export default DashboardPage

