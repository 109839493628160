import { ApolloClient, createHttpLink, from, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error"


const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.extensions.code) {
        case "APP_ERROR":
          break
        case "BAD_USER_INPUT":
          console.log("BAD_USER_INPUT")
          break;
        case "UNAUTHENTICATED":
          console.log(graphQLErrors)
          localStorage.removeItem('userData')
          break;
        /*
        if (!window.location.pathname.includes("login")) {
            window.location.href = `/login`
          }
          */

        /*
        return fromPromise(
            refre({ variables: { data } }).then(async (data) => {
                await fetch("/api/refreshToken", {
                  method: "post",
                  body: JSON.stringify({
                    [LOGIN_TOKEN_KEY]: data.data?.login.token,
                    [LOGIN_REFRESH_TOKEN_KEY]: data.data?.login.refreshToken,
                  }),
                }) }).catch(() => {
                    if (!window.location.pathname.includes("login")) {
                        window.location.href = `/login`
                      }
                      return false
                }) 
        )
          .filter(Boolean)
          .flatMap(() => forward(operation))
          */
        default:
          console.error(graphQLErrors)
          break;
      }
    }
  }
})

const httpLink = createHttpLink({ uri: process.env.REACT_APP_API_DOMAIN + '/api/graphql' })


const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
    query: {
      fetchPolicy: 'network-only'
    }
  },
});



export default client;