
import { Typography } from '@mui/material';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CustomAvatar from 'src/@core/components/mui/avatar'
import Box from '@mui/material/Box';

interface Props {
  title: string
  color: "primary" | "secondary" | "error" | "warning" | "info" | "success"
  description: string
}

const SuccessToast = (props: Props) => {

  const { title, description, color } = props;

  return (
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CustomAvatar
            color={color}
            skin='light'
            variant="rounded"
            sx={{ mr: 3, width: '2.3rem', height: '2.3rem' }}
          >
            <DoneRoundedIcon fontSize="medium" />
          </CustomAvatar>
          <Box>
            <Typography variant='subtitle1'>{title}</Typography>
            <Typography variant='caption'>{description}</Typography>
          </Box>
        </Box>
      </Box>
  )
}

export default SuccessToast;
