// ** React Imports
import { useEffect, useState } from "react";

// ** Library Imports
import { socket } from "src/socket";

// ** MUI Imports
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";

// ** Component Imports
import TemperatureAndHumidityLiveChart from "src/@core/components/charts/TemperatureAndHumidityLiveChart";
import VibrationLiveChart from "src/@core/components/charts/VibrationLiveChart";
import ConsolidatedLiveChart from "src/@core/components/charts/ConsolidatedLiveChart";

// ** Hook Imports
import { useParams } from "react-router-dom";
import VibrationChart from "src/@core/components/charts/VibrationChart";

// ** Type Import

// ** Types
export enum IColorsType {
  active = "success",
  pending = "warning",
  inactive = "secondary",
}

const ReportViewPage = () => {
  const params = useParams();

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      const data = {
        temperature:  Math.random() * (75 - 60) + 60,
        humidity: 60 + Math.random(),
        pressure: Math.random() * (900 - 700) + 700,
        airQuality: 50 + Math.random(),
        gasPercentage:
          Math.random() * (99.17938232 - 74.18775177) + 74.18775177,
        gasResistance: Math.random() * (1856.504028 - 771.007019) + 771.007019,
        co2: Math.random() * (2026.407471 - 598.8737183) + 2026.407471,
        xRMS: Math.random() * (1 - 0.522642994) + 0.522642994,
        yRMS: Math.random() * (0.623718915 - 0.086746435) + 0.086746435,
        zRMS: Math.random() * (0.623718915 - 0.086746435) + 0.086746435,
        time: Math.floor(Date.now() / 1000),
        sensorId: "657d8b983d3e0cba33a1e519",
        isDisabled: false,
        isDeleted: false,
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      setXRMS(data.xRMS);
      setYRMS(data.yRMS);
      setZRMS(data.zRMS);
      setTemperature(data.temperature)
      setPressure(data.pressure)
      setTime(new Date());

    }, 1000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, []);

  // ** States
  const [temperature, setTemperature] = useState<number>(0);
  const [humidity, setHumidity] = useState<number>(0);
  const [xRMS, setXRMS] = useState<number>(0);
  const [yRMS, setYRMS] = useState<number>(0);
  const [zRMS, setZRMS] = useState<number>(0);
  const [pressure, setPressure] = useState<number>(0);
  const [co2, setCO2] = useState<number>(0);
  const [gasPercentage, setGasPercentage] = useState<number>(0);
  const [gasResistance, setGasResistance] = useState<number>(0);
  const [airQuality, setAirQuality] = useState<number>(0);
  const [time, setTime] = useState<Date>(new Date());

  useEffect(() => {});

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <VibrationLiveChart
          label={"Vibration Live"}
          xRMS={xRMS}
          yRMS={yRMS}
          zRMS={zRMS}
          time={time}
        />
      </Grid>
      <Grid item xs={12}>
        <TemperatureAndHumidityLiveChart
          label={"Temperature Live"}
          temperature={temperature}
          time={time}
        />
      </Grid>
      <Grid item xs={12}>
        <ConsolidatedLiveChart
          label={"RPM Live"}
          pressure={pressure}
          time={time}
        />
      </Grid>
    </Grid>
  );
};

export default ReportViewPage;
