import React, { useContext, useEffect, useState } from "react"

// ** MUI Imports
import Box, { BoxProps } from '@mui/material/Box'
import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'


// ** Icons Imports
import DotsVertical from 'mdi-material-ui/DotsVertical'
import DataSaverOffRoundedIcon from '@mui/icons-material/DataSaverOffRounded';
// ** Custom Components Imports
import CustomAvatar from 'src/@core/components/mui/avatar'

// ** Types Imports
import { AssetInfoCardProps, SensorInfoCardProps } from 'src/@core/components/card-statistics/types'
import { Button, ButtonGroup, CardHeader, Grid, ToggleButton, ToggleButtonGroup, styled } from "@mui/material"
import AdjustRoundedIcon from '@mui/icons-material/AdjustRounded';

import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import DirectionsTransitRoundedIcon from '@mui/icons-material/DirectionsTransitRounded';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { ModalContext } from "src/context/modalContext"
import SettingsInputSvideoRoundedIcon from '@mui/icons-material/SettingsInputSvideoRounded';
import GraphicEqRoundedIcon from '@mui/icons-material/GraphicEqRounded';
import DeviceThermostatRoundedIcon from '@mui/icons-material/DeviceThermostatRounded';

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: 7,
  px: 12,
  paddingTop: 16,
  paddingBottom: 16
}))

const SensorInfoCard = (props: SensorInfoCardProps) => {
  // ** Props
  const { title, sensorNumber, color } = props

  const { setLocationModal, locationModal } = useContext(ModalContext)

  const [RPM, setRPM] = useState<number>(0)
  const [temperature, setTemperature] = useState<number>(0)
  const [vibration, SetVibration] = useState<number>(0)

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      const data = {
        temperature:  Math.random() * (75 - 60) + 60,
        humidity: 60 + Math.random(),
        pressure: Math.random() * (900 - 700) + 700,
        airQuality: 50 + Math.random(),
        gasPercentage:
          Math.random() * (99.17938232 - 74.18775177) + 74.18775177,
        gasResistance: Math.random() * (1856.504028 - 771.007019) + 771.007019,
        co2: Math.random() * (2026.407471 - 598.8737183) + 2026.407471,
        xRMS: Math.random() * (1 - 0.522642994) + 0.522642994,
        yRMS: Math.random() * (0.623718915 - 0.086746435) + 0.086746435,
        zRMS: Math.random() * (0.623718915 - 0.086746435) + 0.086746435,
        time: Math.floor(Date.now() / 1000),
        RPM: Math.random() * (900 - 700) + 700,
        vibration: Math.random() * (9.9 - 0.522642994) + 0.522642994,
        sensorId: "657d8b983d3e0cba33a1e519",
        isDisabled: false,
        isDeleted: false,
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      SetVibration(data.xRMS);
      setTemperature(data.temperature)
      setRPM(data.pressure)

    }, 1000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, []);

  return (
    <Grid item xs={6}>
      <Card>
        <CardContent sx={{ py: theme => `${theme.spacing(3)} !important`, px: theme => `${theme.spacing(3)} !important` }}>
          <Grid container item justifyContent='space-between' alignItems='center' spacing={5}>
            <Grid item container xs={12}>
              <Grid item xs={12} >
                <Typography variant='subtitle1'>{title}</Typography>
              </Grid>
              <Grid item container xs={12} my={3}>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CustomAvatar
                      skin='light'
                      color={color}
                      sx={{ mr: 2.5, width: '2rem', height: '2rem', borderRadius: '7px !important' }}
                    >
                      <SettingsInputSvideoRoundedIcon />
                    </CustomAvatar>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant='subtitle1' sx={{ lineHeight: 1.2 }}>NU 202</Typography>
                      <Typography variant='caption' sx={{ lineHeight: 1.2 }}>Bearing Info</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CustomAvatar
                      skin='light'
                      color={color}
                      sx={{ mr: 2.5, width: '2rem', height: '2rem', borderRadius: '7px !important' }}
                    >
                      <GraphicEqRoundedIcon />
                    </CustomAvatar>
                    <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                      <Typography variant='subtitle1' sx={{ lineHeight: 1.2 }}>{vibration.toFixed(2)} gE</Typography>
                      <Typography variant='caption' sx={{ lineHeight: 1.2 }}>Vibration</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid item container xs={12} my={3}>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CustomAvatar
                      skin='light'
                      color={color}
                      sx={{ mr: 2.5, width: '2rem', height: '2rem', borderRadius: '7px !important' }}
                    >
                      <AdjustRoundedIcon />
                    </CustomAvatar>
                    <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                      <Typography variant='subtitle1' sx={{ lineHeight: 1.2 }}>{RPM.toFixed(0)}</Typography>
                      <Typography variant='caption' sx={{ lineHeight: 1.2 }}>RPM</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CustomAvatar
                      skin='light'
                      color={color}
                      sx={{ mr: 2.5, width: '2rem', height: '2rem', borderRadius: '7px !important' }}
                    >
                      <DeviceThermostatRoundedIcon />
                    </CustomAvatar>
                    <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                      <Typography variant='subtitle1' sx={{ lineHeight: 1.2 }}>{temperature.toFixed(0)}°C </Typography>
                      <Typography variant='caption' sx={{ lineHeight: 1.2 }}>Temprature</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>

  )
}

export default SensorInfoCard

SensorInfoCard.defaultProps = {
  color: 'primary',
}
